import { Component, OnInit, ViewChildren } from '@angular/core';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { BrokerService } from 'src/app/core/services/broker.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pad',
  templateUrl: './pad.component.html',
  styleUrls: ['./pad.component.scss']
})
export class PadComponent implements OnInit {
  public dataJsonAliados: any[] = [];
  public testimonials: any[] = [];
  public linkLogin = environment.enlaceLogin;
  @ViewChildren('testimonials') things: any;
  constructor(private utilities: UtilitiesService, private _title: Title, private _metaService: Meta, private broker: BrokerService, public sesion: SesionService, public router: Router) { }

  ngOnInit(): void {
    var link = document.createElement('style');
    link.id = "delete-datina";
    link.textContent = `.padreButtonFormulario {display:none}`;
    document.head.appendChild(link);
    // link.href = environment.styleDatina;
    this.getAliados();
    this.setMetaData();
  }


  ngOnDestroy() {
    let styleDatina = document.getElementById('delete-datina');
    if (styleDatina != undefined) {
      styleDatina.remove();
    }
  }

  ngAfterViewInit() {
    this.things.changes.subscribe((t: any) => {
      this.setQualify();
    })
    // setTimeout(() => {
    //   let datina = document.getElementsByClassName('padreButtonFormulario')[0] as HTMLElement;
    //   if (datina != undefined) {
    //     datina.style.display = 'none';
    //   }
    // }, 2000)
  }

  public checkDivPadYa1 = false;
  checkBannerPadYa1() {
    if (!this.checkDivPadYa1) {
      let tamano = [[728, 90], [300, 250]];
      this.utilities.addPubliById(environment.slotsBanners.padYa1, 'div-gpt-ad-1720186614588-0', tamano);
      this.checkDivPadYa1 = true;
    }
    return '';

  }

  public checkDivPadYa2 = false;
  checkBannerPadYa2() {
    if (!this.checkDivPadYa2) {
      let tamano = [[728, 90], [300, 250]];
      this.utilities.addPubliById(environment.slotsBanners.padYa2, 'div-gpt-ad-1720187114721-0', tamano);
      this.checkDivPadYa2 = true;
    }
    return '';

  }

  setMetaData() {
    this._title.setTitle('Ponte al día con tu deuda reportada en Datacrédito');
    this._metaService.updateTag({
      name: 'description', content: 'Descubre cómo negociar tus deudas con nuestro aliados. Soluciones efectivas para mejorar tu situación financiera de manera fácil, segura y oportuna'
    });
    this._metaService.updateTag({
      name: 'keywords', content: 'Deuda, Renegociación de deudas, negociación, soluciones, datacrédito, saldo en mora'
    });
  }

  /**
   * Funcion que llama al archivo aliados.json que esta en el s3 para cargar los aliados y testimoniales
   */
  getAliados() {
    this.broker.getAlliesPad().subscribe({
      next: (res: any) => {
        this.dataJsonAliados = res.aliadosPadYa;
        this.testimonials = res.testimonials;
      }
    });
  }

  viewInfo(info: number) {
    let box = document.getElementById('box-detail-' + info) as any;
    if (info == 1) {
      let otherBox = document.getElementById('box-detail-2') as any;
      otherBox.classList.add('d-none');
    } else {
      let otherBox = document.getElementById('box-detail-1') as any;
      otherBox.classList.add('d-none');
    }
    box.classList.remove('d-none');
  }

  setQualify() {
    this.testimonials.forEach((ele: any, index: number) => {
      let boxStars = document.getElementById('qualify-testimonial-' + index);
      let boxStarsM = document.getElementById('qualify-testimonial-m-' + index);
      let totalStar = 5;
      if (boxStars != undefined && boxStarsM != undefined) {
        for (let index = 0; index < ele.qualify; index++) {
          let node = document.createElement('i');
          node.style.color = '#F39734';
          node.style.marginRight = '2px';
          node.classList.add('fa', 'fa-star', 'orange');
          boxStars.appendChild(node.cloneNode(true));
          boxStarsM.appendChild(node.cloneNode(true));
        }
        for (let index = 0; index < totalStar - ele.qualify; index++) {
          let node = document.createElement('i');
          node.style.marginRight = '2px';
          node.classList.add('fa', 'fa-star');
          boxStars.appendChild(node.cloneNode(true));
          boxStarsM.appendChild(node.cloneNode(true));
        }
      }
    })
  }


  goHome() {
    if (this.sesion.loggedIn) {
      this.router.navigate(['/']);
    } else {
      window.location.href = this.linkLogin+'&view=pntd';
    }
  }


}
