import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SesionService } from '../../core/services/sesion.service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { Router } from '@angular/router';
import { DataLayersService } from 'src/app/core/services/data-layers.service';

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  linkDc = environment.enlaceBuscaCredito;
  Bc = environment.buscacredito;
  url_politica_privacidad = environment.enlaceS3 + environment.s3.politicaDePrivacidad;
  url_terminos_condiciones = environment.enlaceS3 + environment.s3.terminosYCondiciones;
  anio = new Date().getFullYear();
  public footerPay = false;

  constructor(
    public sesion: SesionService,
    public broker: BrokerService,
    private router: Router,
    private dataLayerService: DataLayersService ) { }

  ngOnInit() {

  }

  verifyUrl() {
    this.footerPay = false;
    if (this.router.url == "/pago") {
      this.footerPay = true;
    }
    return "ok-url";
  }

  scrollPlanes() {
    $('html, body').animate({
      scrollTop: $('#planesPreId').offset().top
    }, 1000);
  }
  scroll() {
    let planSel = document.querySelector('#planesPreId');
    if(planSel != null){
      planSel.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  showBodylinks(ele:any) {
    var body = $($(".footer-links")[ele]).children(".body-links");
    var head = $($(".footer-links")[ele]).children(".head-links");
    if (body.css("display") == "none") {
      body.show();
      head.children("i").removeClass("fa-plus");
      head.children("i").addClass("fa-minus");
    } else {
      body.hide();
      head.children("i").removeClass("fa-minus");
      head.children("i").addClass("fa-plus");
    }
    // $($(".footer-links")[ele]).children(".body-links").show();
  }

  /**
   * Esta función envia un data layer de GA4 "clic_footer_menu" por cada opción que el usuario elija.
   * @param producto Variable en donde se almacena la opción seleccionada por el usuario.
   */
  clic_footer_menu( producto: string ){
    this.dataLayerService.dl_eventoGA4_menu('clic_footer_menu', `${producto} | Footer` );
  }

  irServicioCliente() {
		if (this.sesion.viewFree && this.sesion.cookieValue) {
			this.router.navigate(['/freemium/servicio-cliente']);
		} else if(!this.sesion.viewFree && this.sesion.cookieValue) {
			this.router.navigate(['/private/servicio-cliente']);
		} else{
      this.router.navigate(['/servicio-cliente']);
    }
	}
}
