<div class="generalF">
    <div class="header-feria">
        <div class="div-banner">
            <img class="pieza-midc" src="assets/img/landings/feria/FERIA_MIDATACREDITO.svg" alt="Feria Midatacrédito">
            <img class="pieza-pad" src="assets/img/landings/feria/ESLOGAN_FERIA.svg" alt="Ponte al día">
            <img class="pieza-women" src="assets/img/landings/feria/IMAGEN-8.png" alt="Chica Midatacrédito">
            <img class="pieza-triangule" src="assets/img/landings/feria/INGRESO GRATIS.svg" alt="triangulo">
        </div>
        <div class="headerMobile d-block d-md-none">
            <img class="pieza-midc" src="assets/img/landings/feria/FERIA_MIDATACREDITO.svg" alt="Feria Midatacrédito">
            <img class="pieza-pad" src="assets/img/landings/feria/ESLOGAN_FERIA.svg" alt="Ponte al día">
            <img class="pieza-women" src="assets/img/landings/feria/IMAGEN-8.png" alt="Chica Midatacrédito">
            <img class="pieza-triangule" src="assets/img/landings/feria/INGRESO GRATIS.svg" alt="triangulo">
            <div class="clockMobile">
                <div class="clockItems">
                    <div class="segment">
                        <p><span>{{day <= 0? '0':day}}</span> <br>Días</p>
                    </div>
                    <div class="segment">
                        <p><span>{{hours <= 0? '0':hours}}</span> <br>Horas</p>
                    </div>
                    <div class="segment">
                        <p><span>{{minutes <= 0? '0':minutes}}</span> <br>Minutos</p>
                    </div>
                    <div class="segment">
                        <p><span>{{seconds <= 0? '0':seconds}}</span> <br>Segundos</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="principal">
        <img class="imgMora d-none d-md-block" src="assets/img/landings/feria/Mordiscos.svg" alt="MORA">
        <img class="imgMora d-block d-md-none" src="assets/img/landings/feria/Mordiscos_mobile.svg" alt="MORA">
        <p class="principalTXT">La feria <b>Midatacrédito 2024</b>, será un evento en el que podrás negociar con <b>más de 10 aliados</b> y obtener <span>INCREÍBLES DESCUENTOS</span> en tus deudas o cartera pendiente. <br><br>
        Además, podrás conocer <b>GRATIS tus reportes en DataCrédito</b>, conocer las <b>ofertas financieras</b> que tiene <b>Buscacrédito</b> y mucho más. <br><br>
         ¡Te esperamos para que empieces a tomar el <b>control de tus finanzas</b>!</p>
    </div>
    <div class="clockDesktop d-none d-md-block">
        <div class="clockItems">
            <div class="segment">
                <p><span>{{day <= 0? '0':day}}</span> <br>Días</p>
            </div>
            <div class="segment">
                <p><span>{{hours <= 0? '0':hours}}</span> <br>Horas</p>
            </div>
            <div class="segment">
                <p><span>{{minutes <= 0? '0':minutes}}</span> <br>Minutos</p>
            </div>
            <div class="segment">
                <p><span>{{seconds <= 0? '0':seconds}}</span> <br>Segundos</p>
            </div>
        </div>
    </div>
    <div class="generalInfo">
        <div class="data">
            <img src="assets/img/landings/feria/CUANDO.png" alt="Sábado 27 Abril">
            <h4>¿Cuándo?</h4>
            <p><b>Sábado 27 Abril</b> 2024</p>
        </div>
        <div class="data">
            <img src="assets/img/landings/feria/DONDE.png" class="map" alt="Av. 68 # 49A-47">
            <h4>¿Dónde?</h4>
            <p>Compensar, <b>Plaza de Jubileo</b> Av. 68 # 49A-47, Bogotá D.C., Colombia</p>
        </div>
        <div class="data">
            <img src="assets/img/landings/feria/A-QUE-HORA.png" alt="8:00 a.m a 5:00 p.m">
            <h4>¿A qué hora?</h4>
            <p>8:00 a.m a 5:00 p.m</p>
        </div>
        <div class="dataMobile d-flex d-md-none">
            <img src="assets/img/landings/feria/CUANDO.png" alt="Sábado 27 Abril">
            <div class="txtMobile">
                <h4>¿Cuándo?</h4>
                <p><b>Sábado 27 Abril</b> 2024</p>
            </div>
        </div>
        <hr class="sep">
        <div class="dataMobile d-flex d-md-none">
            <img src="assets/img/landings/feria/DONDE.png" class="map" alt="Av. 68 # 49A-47">
            <div class="txtMobile">
                <h4>¿Dónde?</h4>
                <p>Compensar, <b>Plaza de Jubileo</b> Av. 68 # 49A-47, Bogotá D.C., Colombia</p>
            </div>
        </div>
        <hr class="sep">
        <div class="dataMobile d-flex d-md-none">
            <img src="assets/img/landings/feria/A-QUE-HORA.png" alt="8:00 a.m a 5:00 p.m">
            <div class="txtMobile">
                <h4>¿A qué hora?</h4>
                <p>8:00 a.m a 5:00 p.m</p>
            </div>
        </div>
    </div>
    <div class="banner-div">
        <div id='div-gpt-ad-1712871841570-0' class="commercial-banner" [ngClass]="checkBanner()"></div>
    </div>
    <div class="sponsor" *ngIf="flagSponsors">
        <!-- <div class="desc">
            <p>Hasta <br> <span>XX%</span> <br> descuento</p>
            <p class="add">+</p>
            <p>Negociación <br> <span>100%</span> <br> gratis</p>
        </div> -->
        <h2 class="txtAliados">Aliados con los que podrás negociar:</h2>
        <div class="aliados">
            <img *ngFor="let sponsor of sponsors" src="{{sponsor.icon}}" alt="{{sponsor.name}}">
        </div>
    </div>
    
    <div class="out">
        <h2>¿Estás fuera de Bogotá?</h2>
        <p>Ingresa a <a href="https://www.midatacredito.com/" target="_blank" (click)="send_datalayer('www.midatacredito.com')" >www.midatacredito.com</a>, inicia sesión o regístrate, revisa tu historia de crédito, haz clic en el botón <b>PONERME AL DÍA</b>, contáctate con las entidades acreedoras y empieza a negociar o ingresa a <a href="https://pontealdia.midatacredito.com/" target="_blank" (click)="send_datalayer('pontealdia.midatacredito.com')">www.pontealdia.midatacredito.com</a>.</p>
        <button (click)="goLogin()">Ir a Midatacrédito</button>
    </div>
</div>