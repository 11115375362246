import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SesionService } from '../../core/services/sesion.service';
import { environment } from '../../../environments/environment';
import { BrokerService } from 'src/app/core/services/broker.service';
import { WebActivityService } from '../../core/services/web-activity.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';

declare var $: any;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	ids: Array<String> = ['planes']

	scroll = false;
	devoluciones = false;
	navComplete = true;
	url = '';
	Bc = environment.buscacredito;
	linkLogin = environment.enlaceLogin;
	linkPerfil = environment.enlacePerfil;
	linkAutorizaciones = environment.enlaceAutorizacion;
	linkBc = environment.enlaceBuscaCredito;

	// Colores
	logoBlanco = false;
	
	notificacionesBlanco = false;
	textoBlanco = false;
	protege = false;
	linklogo = '/';
	tabs = '';
	onlyLogo = false;
	screenSize!:number;
	dataMenu!:any;
	flagNew:boolean=false;
	public menuAbierto = false;
	public urlHome: string = "";

	constructor(
		private router: Router,
		public sesion: SesionService,
		public broker: BrokerService,
		public webActivity: WebActivityService,
		private activateRoute: ActivatedRoute,
		public modal: ModalService,
		private dataLayerService: DataLayersService,
		public routing: RoutingService,
		public utilities:UtilitiesService
	) {
		this.configHeader();
		this.sesion.getCookie();
		if (this.sesion.cookieValue){
			this.broker.validationSession(this.sesion.cookieValue).subscribe({
				next: (resp: any) => {
					this.sesion.user = resp;
				  	this.sesion.loggedIn = true;
				}
			})
		}
		$(function () {
			var navMain = $("#navbarNav_acount");
			navMain.on("click", "a", null, function () {
				navMain.collapse('hide');
			});
		});

		this.activateRoute.queryParams.subscribe((params): any => {
			this.tabs = params['tab'];
		});
	}

	@HostListener('window:scroll', ['$event'])
	onScroll() {
		if (this.router.url == "/comparacionPlanes") {
			$(".header").hide();
		} else {
			$(".header").show();
		}
		if(this.router.url != '/deudas'){
			if (window.pageYOffset !== 0) {
				this.scroll = true;
				//$(".mdc-sm-icon").addClass("filter");
			} else {
				this.scroll = false;
				$(".header").show();
				//$(".mdc-sm-icon").removeClass("filter");      
			}
		}
	}

	@HostListener('window:resize', ['$event'])
		onResize(event:any) {
		if(event.target.innerWidth > 768 && !this.sesion.loggedIn ){
			$("#new-header").hide();
		}
	}

	ngOnInit() {
		$("#new-header").hide();
		this.sesion.getMenu();
		// $(".new-collap").hide();
		this.configHeader();
		this.urlHome = this.router.url;
	}

	redirectExterno(url: string, nametag: string, idtag: string, opcion_menu: boolean ) {
		
		if ( opcion_menu ){ this.clic_header_menu('Consultar mi Historia de crédito | Otras soluciones'); }
		this.webActivity.sendDataAWS("click-" + nametag, idtag, true)
		this.sesion.getCookieServices('REDIRECT') ? window.location.href = url + '&redirect=' + this.sesion.getCookieServices('REDIRECT') : window.location.href = url;
	}

	configHeader() {
		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.scroll = false;
				this.url = val.url.split('?')[0];
				this.url = this.url.split('#')[0];
				if(this.url != "/" || (this.url == "/" && !this.sesion.cookieValue)){
					this.utilities.getUtmParams();
				}
				// if (val.url.split('#')[1] === 'darkweb') {
				// 	$('html, body').animate({
				// 		scrollTop: $('#scrollDKW').offset().top
				// 	}, 2000);
				// }
				// if (this.url.includes('webcheckout')) {
				// 	this.showHeader = false;
				// } else {
				// 	this.showHeader = true;
				// }
				if (this.url === '/protege') {
					this.protege = false;
					this.sesion.linkPlanes = '/protege/seleccion';
					this.linklogo = '/protege';
				} else if (this.url === '/protege' || this.url === '/private/confirmacion' || this.url === '/protege/seleccion' || this.url === '/protege/registro') {
					this.protege = true;
					this.sesion.linkPlanes = '/protege/seleccion';
					this.linklogo = '/protege';
				} else {
					this.protege = false;
					if (!this.sesion.cookieValue) {
						this.sesion.linkPlanes = '/comparacion-planes';
						this.linklogo = '/';
					} else {
						this.sesion.linkPlanes = '/comparacion-planes';
						this.linklogo = '/';
					}
				}
				if (!this.sesion.cookieValue) {
					if (this.url === '/') {
						this.logoBlanco = true;
						this.textoBlanco = true;
					}else if (this.url === '/protege' || this.url === '/private/confirmacion' || this.url === '/protege/seleccion' || this.url === '/protege/registro' ) {
						this.logoBlanco = false;
						this.textoBlanco = true;
					} else if (this.url === '/ComoRedimirTuCodigo') {
						this.logoBlanco = true;
						this.textoBlanco = true;
					} else if (this.url === '/landing/devoluciones') {
						this.devoluciones = false;
						this.navComplete = true;
						this.textoBlanco = true;
					} else if (this.url === '/planes' || this.url === '/alianzas' || this.url === '/colfondos' || this.url === '/poliza' || this.url === '/politicasdeprivacidad' || this.url === '/terminoscondiciones' || this.url === '/protege/seleccion' || this.url === '/protege/registro') {
						this.logoBlanco = false;
						this.textoBlanco = false;
					} else if (this.url === '/error') {
						this.logoBlanco = false;
						this.textoBlanco = false;
					}else if (this.url === '/faq' || this.url === '/contactanos') {
						this.logoBlanco = true;
						this.notificacionesBlanco = false;
						this.textoBlanco = true;
					}else if (this.url === '/deudas') {
						this.scroll = true;
					}
				} else {
					if(this.url === '/'){
						this.logoBlanco = false;
						this.textoBlanco = true;
						this.notificacionesBlanco = true;
						$(".header").addClass("d-block");
						$(".navbar").addClass("d-flex");
						this.onlyLogo = false;
					}else if (this.url === '/protege' || this.url === '/private/confirmacion' || this.url === '/protege/seleccion' || this.url === '/protege/registro') {
						this.logoBlanco = false;
						this.textoBlanco = true;
						this.notificacionesBlanco = true;
					} else if (
						this.url === '/private/planes' || this.url === '/prueba293487' || this.url === '/freemium/home' || this.url === '/private/home' || this.url === '/alianzas' || this.url === '/colfondos' || this.url === '/private/confirmacion' || this.url === '/poliza' || this.url === '/politicasdeprivacidad' || this.url === '/terminoscondiciones' || this.url === '/private/upselling' || this.url === '/modulopadapp') {
						this.logoBlanco = false;
						this.textoBlanco = false;
						this.notificacionesBlanco = false;
					} else if (this.url === '/pago' || this.url === '/private/confirmacion') {
						$(".header").addClass("d-none");
						$(".navbar").addClass("d-none");
						this.onlyLogo = true;
					} else if (this.url === '/error') {
						this.logoBlanco = false;
						this.textoBlanco = false;
						this.notificacionesBlanco = false;
					}else if (this.url === '/faq' || this.url === '/contactanos') {
						this.logoBlanco = true;
						this.notificacionesBlanco = false;
						this.textoBlanco = true;
					} else if (this.url === '/deudas') {
						this.scroll = true;
					}else {
						this.logoBlanco = true;
						this.notificacionesBlanco = false;
						this.textoBlanco = false;
					}
				}
			}
		});
	}


	closeSession() {
		this.clic_header_menu('Cerrar sesión');
		$("#new-header").hide("fast");
		this.menuAbierto = false;
		this.sesion.deleteCookie();
		this.sesion.closeSession();
	}

	openMenu() {
		if (!this.menuAbierto) {
			$("#new-header").slideToggle('slow');
			$("#new-header").focus();
			this.menuAbierto = true;
			this.clic_header_menu('Abrir menú');
		} else {
			$("#new-header").slideToggle('fast');
			this.menuAbierto = false;
		}
	}

	irHistoriaCredito(fun:string) {
		$("#new-header").hide('fast');
		this.menuAbierto = false;
		if (this.sesion.loggedIn) {
			if (this.sesion.viewFree) {
				this.router.navigate(['/freemium/historico']);
			} else {
				this.router.navigate(['/private'+fun]);
			}
		} else {
			this.redirectExterno(this.linkLogin, 'login-iniciaSesion', 'HEADER1', false)
		}
	}

	irHabeasData() {
		$("#new-header").hide('fast');
		this.menuAbierto = false;
		this.router.navigate(['/politicasdeprivacidad'])
	}

	irOtrasSoluciones( texto_boton: string ){
		$("#new-header").hide('fast');
		this.menuAbierto = false;
		this.textoBlanco = false;
		this.clic_header_menu(texto_boton);
	}

	irMiplan() {
		$("#new-header").hide('fast');
		this.menuAbierto = false;
		this.clic_header_menu('Mi plan | Menú');
		if ((this.sesion.viewFree) && this.sesion.planCurrently == undefined) {
			this.router.navigate(['/comparacion-planes']);
		} else if ((this.sesion.viewFree) && this.sesion.planCurrently != undefined) {
			this.router.navigate(['/freemium/mi-plan']);
		} else {
			this.router.navigate(['/private/mi-plan']);
		}
	}

	irServicioCliente() {
		$("#new-header").hide('fast');
		this.menuAbierto = false;
		this.clic_header_menu('Servicio al cliente | Menú');
		if (this.sesion.viewFree && this.sesion.cookieValue) {
			this.router.navigate(['/freemium/servicio-cliente']);
		} else if(!this.sesion.viewFree && this.sesion.cookieValue) {
			this.router.navigate(['/private/servicio-cliente']);
		} else{
		this.router.navigate(['/servicio-cliente']);
		}
	}

	irVerificar() {
		$("#new-header").hide('fast');
		this.menuAbierto = false;
		this.modal.modalVerifica()
	}

	textNotification(index:number) {
		if (this.sesion.viewFree) {
			return this.sesion.dataMenu[index].descriptionFree;
		}
			return this.sesion.dataMenu[index].description;
	}

	// getMenu(){
	// 	this.broker.getMenu()
	// 	.subscribe(
	// 	  {
	// 		next: (data: any) => {
	// 		  this.sesion.dataMenu =data.items;
	// 		  this.sesion.flagNew = data.new;
	// 		},
	// 		error: (error) => {
	// 		}
	// 	  }
		  
	// 	);
	//   }
	
	  selectEvent(fun:string,idS:string){
		switch(fun){
			case "/buscacredito":
		  case "/huellas":
		  case "/mis-notificaciones":
		  case "/generar-alerta":
		  case "/historico":
			case "/simulador":
		  this.routing.redirectAllAccess(0,fun);
		  break;


		  case "/historico-score":
		  case "/mi-proximo-credito":
		  case "/mi-deuda":
		  case "/diagnostico":
		  this.routing.redirectPayAccess(0,fun);
		  break;

		  case "/home":
			  if(idS == '9'){
				  this.routing.redirectAllAccess(idS,fun,'modulo-bc');
				}else{
				  this.routing.redirectAllAccess(0,fun);
			  }
		  break;
		//   case "irHistoriaCredito":
		//   this.irHistoriaCredito(fun);
		//   break;
		  
		  case "/mi-presencia":
		  this.routing.irPresencia(null);
		  break;
		  case "/poliza-premium":
		  this.routing.irProteccion(null);
		  break;
		  default:
		  this.irHistoriaCredito(fun);
		  break;
		}
	  }
	  accessMenu(typePlan:any){
		if(typePlan.monitoreo != undefined){
			switch(typePlan.name){
	  
			  case"PER":
			  if((this.sesion.monitoreo == typePlan.monitoreo && (this.sesion.estadoUserF == "ACTIVO" || this.sesion.estadoUserF == "ACTIVE_PROCESS"))){
				return false;
			  }else{
				return true;
			  }
			  break;
			  case"ANT":
			  if((this.sesion.monitoreo == typePlan.monitoreo)&&
					(this.sesion.proteccion == typePlan.proteccion)  && (this.sesion.estadoUserF == "ACTIVO" || this.sesion.estadoUserF == "ACTIVE_PROCESS")){
				  return false;
				}else{
				  return true;
				}
			  break;
			  case"PRO":
			  if((this.sesion.monitoreo == typePlan.monitoreo)&&
					(this.sesion.proteccion == typePlan.proteccion)&&
					(this.sesion.poliza == typePlan.poliza)  && (this.sesion.estadoUserF == "ACTIVO" || this.sesion.estadoUserF == "ACTIVE_PROCESS")){
				  return false;
				}else{
				  return true;
				}
			  default:
				return false;
			}
		  }else{
			return false;
		  }
		
	}

	setLogo(){
		if(!this.scroll && !this.logoBlanco){
			return "assets/img/logos/logo_midc.svg";
		}else{
			return "assets/img/logos/logo_midc_white.svg";
		}
	}

	/**
	 * Este método envía un data layer de GA4 "clic_header_menu" por cada click en los botones del menú
	 * @param texto Variable que almacena el nombre de la opción que se enviará
	 */
	clic_header_menu( texto:string ){
		this.dataLayerService.dl_eventoGA4_menu( 'clic_header_menu', texto );
	}

	setAdvance(url:string){
		if(this.sesion.viewFree || (url != "/simulador" && url != "/historico-score")){
			return true;
		}else if(!this.sesion.viewFree && url == "/simulador"){
			return (this.sesion.dataUser.params[13].value)?true:false;
		}else if(!this.sesion.viewFree && url == "/historico-score"){
			return (this.sesion.dataUser.params[13].value)?false:true;
		}
		else{
			return true;
		}
	}

	closeMenu(){
		$("#new-header").hide('fast');
		this.menuAbierto = false;
	}
}
