<section class="detail-account">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12 px-0 px-md-3">
				<div class="container-acccount">
					<div>
						<div class="box-account">
							<a class="back" (click)="irHistoriaCredito()">
								<i class="fa fa-arrow-left" aria-hidden="true"></i>
								Volver a Historia de Crédito
							</a>
							<div class="box-column">
								<div class="account">
									<div class="box-bank">
										<div class="top">
											<div class="img-bank">
												<!-- Bancos -->
												<img alt="CCB" *ngIf="cta().tipoCuenta === 'AHO' || cta().tipoCuenta === 'CCB'"
													src="assets/img/freepremium/tipo-producto/Bancos-CUENTA_AHORRO.svg" />
												<img alt="TDC" *ngIf="cta().tipoCuenta === 'TDC'"
													src="assets/img/freepremium/tipo-producto/Bancos-TARJETA_CREDITO.svg" />
												<img alt="CAB" *ngIf="cta().tipoCuenta === 'CAB'"
													src="assets/img/freepremium/tipo-producto/Bancos-PRESTAMO_BANCARIO.svg" />
												<img alt="CAV" *ngIf="cta().tipoCuenta === 'CAV'"
													src="assets/img/freepremium/tipo-producto/Bancos-CARTERA_AHORRO_VIVIENDA.svg" />
												<img alt="CCF" *ngIf="cta().tipoCuenta === 'CCF'"
													src="assets/img/freepremium/tipo-producto/Bancos-COMPANIA_FINANCIAMIENTO_COMERCIAL.svg" />
												<img alt="CBR" *ngIf="cta().tipoCuenta === 'CBR' || cta().tipoCuenta === 'SBG'"
													src="assets/img/freepremium/tipo-producto/Bancos-PRESTAMO_ROTATIVO.svg" />
												<img alt="CAU" *ngIf="cta().tipoCuenta === 'CAU'"
													src="assets/img/freepremium/tipo-producto/Bancos-PRESTAMO_VEHICULO.svg" />
												<img alt="CCL" *ngIf="cta().tipoCuenta === 'CCL'"
													src="assets/img/freepremium/tipo-producto/Bancos-PRESTAMO_LEASING.svg" />
												<!-- Telecomunicaciones -->
												<img alt="Tipo cuenta" *ngIf="
												cta().tipoCuenta === 'CTC' || cta().tipoCuenta === 'CDC' ||
												cta().tipoCuenta === 'CDC' || cta().tipoCuenta === 'CDC' "
													src="assets/img/freepremium/tipo-producto/Telecomunicaciones.svg" />
												<!-- Servicios -->
												<img alt="Tipo cuenta" *ngIf="
												cta().tipoCuenta === 'CAC' || cta().tipoCuenta === 'COF' || 
												cta().tipoCuenta === 'CVE' || cta().tipoCuenta === 'CLB' || 
												cta().tipoCuenta === 'COC' || cta().tipoCuenta === 'CCC' || 
												cta().tipoCuenta === 'CAF' || cta().tipoCuenta === 'CMU' || 
												cta().tipoCuenta === 'CEL' || cta().tipoCuenta === 'DIC' ||
												cta().tipoCuenta === 'CDB' || cta().tipoCuenta === 'CSP' || 
												cta().tipoCuenta === 'AGR' || cta().tipoCuenta === 'ALI' || 
												cta().tipoCuenta === 'CMZ' || cta().tipoCuenta === 'COM' || 
												cta().tipoCuenta === 'FER' || cta().tipoCuenta === 'FUN' ||
												cta().tipoCuenta === 'GRM' || cta().tipoCuenta === 'IND' || 
												cta().tipoCuenta === 'LAB' || cta().tipoCuenta === 'MSJ' || 
												cta().tipoCuenta === 'SEG' || cta().tipoCuenta === 'TRT' || 
												cta().tipoCuenta === 'EDU' || cta().tipoCuenta === 'SFI' || 
												cta().tipoCuenta === 'CON' || cta().tipoCuenta === 'APD' ||
												cta().tipoCuenta === 'CAS' || cta().tipoCuenta === 'MCR' || 
												cta().tipoCuenta === 'CFE' || cta().tipoCuenta === 'CTU' || 
												cta().tipoCuenta === 'LBZ' || cta().tipoCuenta === 'CFR' ||
												cta().tipoCuenta === 'CCS' || cta().tipoCuenta === 'PCS' || 
												cta().tipoCuenta === 'CSA'" src="assets/img/freepremium/tipo-producto/Servicios.svg" />
												<img alt="CFR" *ngIf="cta().tipoCuenta === 'CFR'"
													src="assets/img/freepremium/tipo-producto/Servicios-FINCA_RAIZ.svg" />
												<img alt="CCS" *ngIf="cta().tipoCuenta === 'CCS'"
													src="assets/img/freepremium/tipo-producto/Servicios-SEGUROS.svg" />
												<img alt="PCS" *ngIf="cta().tipoCuenta === 'PCS'"
													src="assets/img/freepremium/tipo-producto/Servicios-PENSIONES_CESANTIAS.svg" />
												<img alt="CSA" *ngIf="cta().tipoCuenta === 'CSA'"
													src="assets/img/freepremium/tipo-producto/Servicios-CAJA_COMPENSACION_SALUD.svg" />
											</div>
											<div class="txt-bank">
												<h2>{{cta().entidad}}</h2>
												<h3>
													<span *ngIf="name() === 'Mis cuentas de ahorro'">Cuenta de ahorro
														No. </span>
													<span *ngIf="name() === 'Mis tarjetas de crédito'">Tarjeta de
														crédito No. </span>
													<span *ngIf="name() === 'Mis préstamos bancarios'">Prestamo No.
													</span>
													<span *ngIf="name() === 'Mis telecomunicaciones'">Obligación No.
													</span>
													<span *ngIf="name() === 'Otros'">Obligación No.</span>
													{{cta().numeroCuenta}}
												</h3>
											</div>
										</div>
										<div class="down">
											<div class="state">
												<h5>Estado</h5>
												<p>
													<span
														*ngIf="cta().estado && (name() !== 'Mis cuentas de ahorro' && name() !== 'Mis cuentas corrientes')">{{
														sesion.getStateText(cta().estado, cta().codigoEstadoCuenta) }}</span>
													<span
														*ngIf="cta().estado && (name() === 'Mis cuentas de ahorro' || name() === 'Mis cuentas corrientes')">{{
														sesion.getStateTextCtas(cta().estado) }}</span>
													<span *ngIf="!cta().estado">-</span>
												</p>
												<img src="assets/img/freepremium/tipo-producto/Reporte.svg" alt="Reporte"/>
												<p class="date">Última actualización el <span>{{ cta().fechaActualizacion | date: 'dd MMMM yyyy' }}</span>.</p>
											</div>
										</div>

									</div>
								</div>
								<div class="balance">
									<div class="content-table">
										<h5>Saldo</h5>
										<p>
											<span *ngIf="cta().saldoActual">$ {{ cta().saldoActual | currency:'COP':'':'1.0-0'}}</span>
											<span *ngIf="!cta().saldoActual">No aplica</span>
										</p>
									</div>
									<div class="content-table">
										<h5>Tipo de reporte</h5>
										<p class="report negative"
											[ngClass]="{'negative': cta().tipoReporte === 'Negativo', 'positive': cta().tipoReporte === 'Positivo', 'not-apply': cta().tipoReporte === 'No Aplica' }">
											{{ cta().tipoReporte }}
										</p>
									</div>
									<div class="content-table">
										<h5>Saldo en mora</h5>
										<p>
											<span *ngIf="cta().saldoMora">
												$ {{ cta().saldoMora | currency:'COP':'':'1.0-0'}}
											</span>
											<span *ngIf="!cta().saldoMora">
												No aplica
											</span>
										</p>
									</div>
									<div class="content-table">
										<h5>Fecha lím. Pago</h5>
										<p>
											<span *ngIf="cta().fechaLimitePago">
												{{ cta().fechaLimitePago | date: 'dd MMMM yyyy' }}
											</span>
											<span *ngIf="!cta().fechaLimitePago">
												No aplica
											</span>
										</p>
									</div>
									<div class="upgrade tip"
										*ngIf="cta().fechaPermanencia != null">
										<div class="tooltip-consult">
											<div class="tooltip1 top">
												<i class="fa fa-question-circle" aria-hidden="true" id="clicOut"></i>
												<span class="tiptext">Hasta esta fecha el reporte negativo permanecerá
													en la historia de crédito</span>
											</div>
										</div>
										<p class="title">Permanencia de la información Negativa</p>
										<p *ngIf="cta().fechaPermanencia">{{permanenceFormat(cta().fechaPermanencia)}}
										</p>
									</div>
									<div class="negotiate" *ngFor="let itemMatch of deudasMatch">
										<div *ngIf="itemMatch.numberAccount == cta().numeroCuenta.substr(2,6)">
											<p><span>{{cta().entidad}}</span> es un aliado Midatacrédito, te damos la
												posibilidad de negociar tu saldo en mora por <span>$ {{ cta().saldoMora | currency:'COP':'':'1.0-0'}}</span></p>
											<a class="btn-midc btn-orange h-lg-btn w-100-btn" id="negociarPDA"
												webActivity data-idtag="Freemium" data-nametag="negociarPDA"
												href="https://pontealdia.midatacredito.com/" target="_blank">
												<i class="fa fa-chevron-right" aria-hidden="true"></i>
												Negociar deuda
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
							<div class="boxPad" (click)="openModalPadYa(cta())" *ngIf="isPadYa(cta())">
								<img src="assets/img/freepremium/crear-alertas/nota.svg" alt="Alerta" />
								<div class="pad">
									<p><span class="txtPad">Ponte al día</span> y <span class="midc">Midatacrédito</span> te ayuda a mejorar tu situación financiera a través de la negociación de esta obligación.</p>
									<button class="btnPad"><span *ngIf="!isLoadApplyPadYa">Ponte al día</span><i class="fa fa-chevron-right" *ngIf="!isLoadApplyPadYa"></i><span class="custom-loader" *ngIf="isLoadApplyPadYa"></span></button>
								</div>
							</div>
						<div class="box-detail">							
							<div class="table-detail">
								<div class="upgrade">
									<img src="assets/img/freepremium/crear-alertas/nota.svg" alt="Alerta" />
									<div>
										<p>La información positiva o negativa reflejada en la historia de crédito depende del reporte de las entidades (fuentes de información) a las centrales de riesgo.</p>
										<!-- <p>La amnistía es un beneficio que se otorga por una única vez, por lo cual, si se reincide en mora en la obligación objeto del beneficio, la mora presentada permanecerá visible en la historia de crédito el doble del tiempo, máximo 4 años, como aplicación de la regla general del término de permanencia del dato negativo (art. 13, ley 1266 de 2008).</p> -->
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<h2>Más detalles</h2>
									</div>
									<div class="col-6 col-md-3" [hidden]="name() === 'Mis cuentas de ahorro'">
										<div class="content-table">
											<h5>Cupo inicial</h5>
											<p>
												<span *ngIf="cta().cupoTotal != '-1' && cta().cupoTotal != null">
													$ {{ cta().cupoTotal | currency:'COP':'':'1.0-0'}}
												</span>
												<span *ngIf="cta().cupoInicial != '-1' && cta().cupoInicial != null">
													$ {{ cta().cupoInicial | currency:'COP':'':'1.0-0'}}
												</span>
												<span *ngIf="!cta().cupoInicial && !cta().cupoTotal">
													No aplica
												</span>
												<span *ngIf="cta().cupoInicial == '-1'">
													No aplica
												</span>
												<span *ngIf="cta().cupoTotal == '-1'">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3" [hidden]="name() === 'Mis cuentas de ahorro'">
										<div class="content-table">
											<h5 *ngIf="cta().tipoCuenta != 'AHO' && cta().tipoCuenta != 'CCB'">Valor cuota</h5>
											<h5 *ngIf="cta().tipoCuenta === 'AHO' || cta().tipoCuenta === 'CCB'">Valor cargo fijo</h5>
											<p>
												<span *ngIf="cta().valorCuota">
													$ {{ cta().valorCuota | currency:'COP':'':'1.0-0'}}
												</span>
												<span *ngIf="!cta().valorCuota">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3" [hidden]="name() === 'Mis cuentas de ahorro'">
										<div class="content-table">
											<h5>Saldo en mora</h5>
											<p>
												<span *ngIf="cta().saldoMora">
													$ {{ cta().saldoMora | currency:'COP':'':'1.0-0'}}
												</span>
												<span *ngIf="!cta().saldoMora">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3">
										<div class="content-table">
											<h5>Fecha de actualización</h5>
											<p>
												<span *ngIf="cta().fechaActualizacion">
													{{ cta().fechaActualizacion | date: 'dd MMMM yyyy' }}
												</span>
												<span *ngIf="!cta().fechaActualizacion">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3" [hidden]="name() === 'Mis cuentas de ahorro'">
										<div class="content-table">
											<h5>Cuotas pagadas</h5>
											<p>
												<span *ngIf="cta().cuotasCanceladas != '-1'">
													{{ cta().cuotasCanceladas }}
												</span>
												<span *ngIf="!cta().cuotasCanceladas">
													No aplica
												</span>
												<span *ngIf="cta().cuotasCanceladas == '-1'">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3" [hidden]="name() === 'Mis cuentas de ahorro'">
										<div class="content-table">
											<h5>Marca/clase</h5>
											<p>
												<span *ngIf="cta().marca">
													{{ cta().marca }}
												</span>
												<span *ngIf="!cta().marca">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3">
										<div class="content-table">
											<h5>Oficina</h5>
											<p>
												<span *ngIf="cta().oficina">
													{{ cta().oficina }}
												</span>
												<span *ngIf="!cta().oficina">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3" [hidden]="name() === 'Mis cuentas de ahorro'">
										<div class="content-table">
											<h5>Cuotas pactadas</h5>
											<p>
												<span *ngIf="cta().cuotas != '-1'">
													{{ cta().cuotas }}
												</span>
												<span *ngIf="!cta().cuotas">
													No aplica
												</span>
												<span *ngIf="cta().cuotas == '-1'">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3" [hidden]="name() === 'Mis cuentas de ahorro'">
										<div class="content-table">
											<h5>Fecha límite de pago</h5>
											<p>
												<span *ngIf="cta().fechaLimitePago">
													{{ cta().fechaLimitePago | date: 'dd MMMM yyyy' }}
												</span>
												<span *ngIf="!cta().fechaLimitePago">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3">
										<div class="content-table">
											<h5>Apertura</h5>
											<p>
												<span *ngIf="cta().fechaApertura">
													{{ cta().fechaApertura | date: 'dd MMMM yyyy' }}
												</span>
												<span *ngIf="!cta().fechaApertura">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3" [hidden]="name() === 'Mis cuentas de ahorro'">
										<div class="content-table">
											<h5>Vencimiento</h5>
											<p>
												<span *ngIf="cta().fechaVencimiento">
													{{ cta().fechaVencimiento | date: 'dd MMMM yyyy' }}
												</span>
												<span *ngIf="!cta().fechaVencimiento">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3" [hidden]="name() === 'Mis cuentas de ahorro'">
										<div class="content-table">
											<h5>Fecha del pago</h5>
											<p>
												<span *ngIf="cta().fechaPago">
													{{ cta().fechaPago | date: 'dd MMMM yyyy' }}
												</span>
												<span *ngIf="!cta().fechaPago">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3" [hidden]="name() === 'Mis cuentas de ahorro'">
										<div class="content-table">
											<h5>Reclamo</h5>
											<p>
												<span>No aplica</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3" [hidden]="name() === 'Mis cuentas de ahorro'">
										<div class="content-table">
											<h5>Tipo de contrato celebrado</h5>
											<p>
												<span *ngIf="cta().nomTipoContrato">
													{{ cta().nomTipoContrato }}
												</span>
												<span *ngIf="!cta().nomTipoContrato">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3">
										<div class="content-table">
											<h5>Deudor</h5>
											<p>
												<span *ngIf="cta().titular">
													{{ cta().titular }}
												</span>
												<span *ngIf="!cta().titular">
													No aplica
												</span>
											</p>
										</div>
									</div>
									<div class="col-6 col-md-3" [hidden]="name() === 'Mis cuentas de ahorro'">
										<div class="content-table">
											<h5>Cláusula de permanencia</h5>
											<p>
												<span *ngIf="cta().mesesPermanencia != '0'">
													{{ cta().mesesPermanencia }}
												</span>
												<span *ngIf="cta().mesesPermanencia === '0'">
													No aplica
												</span>
												<span *ngIf="!cta().mesesPermanencia">
													No aplica
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="table-habits" *ngIf="cta().comportamiento">
								<h2>Hábito de pago</h2>
								<ul class="tabs">
									<li [ngClass]="tabActive == 0 ? 'active' : ''"
										*ngFor="let comp of getComportSlice(0,1)">
										<a id="anio2016" (click)="tabsHC(0)">{{ comp.anio }}</a>
									</li>
									<li [ngClass]="tabActive == 1 ? 'active' : ''"
										*ngFor="let comp of getComportSlice(1,2)">
										<a id="anio2016" (click)="tabsHC(1)">{{ comp.anio }}</a>
									</li>
									<li [ngClass]="tabActive == 2 ? 'active' : ''"
										*ngFor="let comp of getComportSlice(2,3)">
										<a id="anio2016" (click)="tabsHC(2)">{{ comp.anio }}</a>
									</li>
									<li [ngClass]="tabActive == 3 ? 'active' : ''"
										*ngFor="let comp of getComportSlice(3,4)">
										<a id="anio2016" (click)="tabsHC(3)">{{ comp.anio }}</a>
									</li>
									<li [ngClass]="tabActive == 4 ? 'active' : ''"
										*ngFor="let comp of getComportSlice(4,5)">
										<a id="anio2016" (click)="tabsHC(4)">{{ comp.anio }}</a>
									</li>
								</ul>

								<div class="tab_container">
									<div class="tab_content-head">
										<div class="month anio-table">AÑO</div>
										<div class="month anio-table">ENE</div>
										<div class="month anio-table">FEB</div>
										<div class="month anio-table">MAR</div>
										<div class="month anio-table">ABR</div>
										<div class="month anio-table">MAY</div>
										<div class="month anio-table">JUN</div>
										<div class="month anio-table">JUL</div>
										<div class="month anio-table">AGO</div>
										<div class="month anio-table">SEP</div>
										<div class="month anio-table">OCT</div>
										<div class="month anio-table">NOV</div>
										<div class="month anio-table">DIC</div>
									</div>
									<div *ngIf="tabActive == 0 || tabActive0" id="tabActive0">
										<div class="tab_content"
											*ngFor="let comp of getComportSlice(0,1); let i = index">
											<div class="month anio-table">{{ comp.anio }}</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[0] === '-' || comp.comp[0] === '',
												'up-to-date': comp.comp[0] === 'N',
												'punished': comp.comp[0] === 'C',
												'dude': comp.comp[0] === 'D',
												'days-30': comp.comp[0] === '1',
												'days-60': comp.comp[0] === '2',
												'more-60-days': comp.comp[0] === '3' || comp.comp[0] === '4' || comp.comp[0] === '5' || comp.comp[0] === '6'
												}">
												<span
													*ngIf="comp.comp[0] === '1' || comp.comp[0] === '2' || comp.comp[0] === '3' || comp.comp[0] === '4' || comp.comp[0] === '5' || comp.comp[0] === '6'">{{
													comp.comp[0] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[0] === '-'" class="d-block d-md-none">ENE - Sin
													información</span>
												<span *ngIf="comp.comp[0] === 'N'" class="d-block d-md-none">ENE - Al
													día</span>
												<span *ngIf="comp.comp[0] === 'C'" class="d-block d-md-none">ENE -
													Cartera castigada</span>
												<span *ngIf="comp.comp[0] === 'D'" class="d-block d-md-none">ENE -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[1] === '-' || comp.comp[1] === '',
												'up-to-date': comp.comp[1] === 'N',
												'punished': comp.comp[1] === 'C',
												'dude': comp.comp[1] === 'D',
												'days-30': comp.comp[1] === '1',
												'days-60': comp.comp[1] === '2',
												'more-60-days': comp.comp[1] === '3' || comp.comp[1] === '4' || comp.comp[1] === '5' || comp.comp[1] === '6'
												}">
												<span
													*ngIf="comp.comp[1] === '1' || comp.comp[1] === '2' || comp.comp[1] === '3' || comp.comp[1] === '4' || comp.comp[1] === '5' || comp.comp[1] === '6'">{{
													comp.comp[1] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[1] === '-'" class="d-block d-md-none">FEB - Sin
													información</span>
												<span *ngIf="comp.comp[1] === 'N'" class="d-block d-md-none">FEB - Al
													día</span>
												<span *ngIf="comp.comp[1] === 'C'" class="d-block d-md-none">FEB -
													Cartera castigada</span>
												<span *ngIf="comp.comp[1] === 'D'" class="d-block d-md-none">FEB -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[2] === '-' || comp.comp[2] === '',
												'up-to-date': comp.comp[2] === 'N',
												'punished': comp.comp[2] === 'C',
												'dude': comp.comp[2] === 'D',
												'days-30': comp.comp[2] === '1',
												'days-60': comp.comp[2] === '2',
												'more-60-days': comp.comp[2] === '3' || comp.comp[2] === '4' || comp.comp[2] === '5' || comp.comp[2] === '6'
												}">
												<span
													*ngIf="comp.comp[2] === '1' || comp.comp[2] === '2' || comp.comp[2] === '3' || comp.comp[2] === '4' || comp.comp[2] === '5' || comp.comp[2] === '6'">{{
													comp.comp[2] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[2] === '-'" class="d-block d-md-none">MAR - Sin
													información</span>
												<span *ngIf="comp.comp[2] === 'N'" class="d-block d-md-none">MAR - Al
													día</span>
												<span *ngIf="comp.comp[2] === 'C'" class="d-block d-md-none">MAR -
													Cartera castigada</span>
												<span *ngIf="comp.comp[2] === 'D'" class="d-block d-md-none">MAR -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[3] === '-' || comp.comp[3] === '',
												'up-to-date': comp.comp[3] === 'N',
												'punished': comp.comp[3] === 'C',
												'dude': comp.comp[3] === 'D',
												'days-30': comp.comp[3] === '1',
												'days-60': comp.comp[3] === '2',
												'more-60-days': comp.comp[3] === '3' || comp.comp[3] === '4' || comp.comp[3] === '5' || comp.comp[3] === '6'
												}">
												<span
													*ngIf="comp.comp[3] === '1' || comp.comp[3] === '2' || comp.comp[3] === '3' || comp.comp[3] === '4' || comp.comp[3] === '5' || comp.comp[3] === '6'">{{
													comp.comp[3] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[3] === '-'" class="d-block d-md-none">ABR - Sin
													información</span>
												<span *ngIf="comp.comp[3] === 'N'" class="d-block d-md-none">ABR - Al
													día</span>
												<span *ngIf="comp.comp[3] === 'C'" class="d-block d-md-none">ABR -
													Cartera castigada</span>
												<span *ngIf="comp.comp[3] === 'D'" class="d-block d-md-none">ABR -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[4] === '-' || comp.comp[4] === '',
												'up-to-date': comp.comp[4] === 'N',
												'punished': comp.comp[4] === 'C',
												'dude': comp.comp[4] === 'D',
												'days-30': comp.comp[4] === '1',
												'days-60': comp.comp[4] === '2',
												'more-60-days': comp.comp[4] === '3' || comp.comp[4] === '4' || comp.comp[4] === '5' || comp.comp[4] === '6'
												}">
												<span
													*ngIf="comp.comp[4] === '1' || comp.comp[4] === '2' || comp.comp[4] === '3' || comp.comp[4] === '4' || comp.comp[4] === '5' || comp.comp[4] === '6'">{{
													comp.comp[4] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[4] === '-'" class="d-block d-md-none">MAY - Sin
													información</span>
												<span *ngIf="comp.comp[4] === 'N'" class="d-block d-md-none">MAY - Al
													día</span>
												<span *ngIf="comp.comp[4] === 'C'" class="d-block d-md-none">MAY -
													Cartera castigada</span>
												<span *ngIf="comp.comp[4] === 'D'" class="d-block d-md-none">MAY -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[5] === '-' || comp.comp[5] === '',
												'up-to-date': comp.comp[5] === 'N',
												'punished': comp.comp[5] === 'C',
												'dude': comp.comp[5] === 'D',
												'days-30': comp.comp[5] === '1',
												'days-60': comp.comp[5] === '2',
												'more-60-days': comp.comp[5] === '3' || comp.comp[5] === '4' || comp.comp[5] === '5' || comp.comp[5] === '6'
												}">
												<span
													*ngIf="comp.comp[5] === '1' || comp.comp[5] === '2' || comp.comp[5] === '3' || comp.comp[5] === '4' || comp.comp[5] === '5' || comp.comp[5] === '6'">{{
													comp.comp[5] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[5] === '-'" class="d-block d-md-none">JUN - Sin
													información</span>
												<span *ngIf="comp.comp[5] === 'N'" class="d-block d-md-none">JUN - Al
													día</span>
												<span *ngIf="comp.comp[5] === 'C'" class="d-block d-md-none">JUN -
													Cartera castigada</span>
												<span *ngIf="comp.comp[5] === 'D'" class="d-block d-md-none">JUN -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[6] === '-' || comp.comp[6] === '',
												'up-to-date': comp.comp[6] === 'N',
												'punished': comp.comp[6] === 'C',
												'dude': comp.comp[6] === 'D',
												'days-30': comp.comp[6] === '1',
												'days-60': comp.comp[6] === '2',
												'more-60-days': comp.comp[6] === '3' || comp.comp[6] === '4' || comp.comp[6] === '5' || comp.comp[6] === '6'
												}">
												<span
													*ngIf="comp.comp[6] === '1' || comp.comp[6] === '2' || comp.comp[6] === '3' || comp.comp[6] === '4' || comp.comp[6] === '5' || comp.comp[6] === '6'">{{
													comp.comp[6] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[6] === '-'" class="d-block d-md-none">JUL - Sin
													información</span>
												<span *ngIf="comp.comp[6] === 'N'" class="d-block d-md-none">JUL - Al
													día</span>
												<span *ngIf="comp.comp[6] === 'C'" class="d-block d-md-none">JUL -
													Cartera castigada</span>
												<span *ngIf="comp.comp[6] === 'D'" class="d-block d-md-none">JUL -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[7] === '-' || comp.comp[7] === '',
												'up-to-date': comp.comp[7] === 'N',
												'punished': comp.comp[7] === 'C',
												'dude': comp.comp[7] === 'D',
												'days-30': comp.comp[7] === '1',
												'days-60': comp.comp[7] === '2',
												'more-60-days': comp.comp[7] === '3' || comp.comp[7] === '4' || comp.comp[7] === '5' || comp.comp[7] === '6'
												}">
												<span
													*ngIf="comp.comp[7] === '1' || comp.comp[7] === '2' || comp.comp[7] === '3' || comp.comp[7] === '4' || comp.comp[7] === '5' || comp.comp[7] === '6'">{{
													comp.comp[7] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[7] === '-'" class="d-block d-md-none">AGO - Sin
													información</span>
												<span *ngIf="comp.comp[7] === 'N'" class="d-block d-md-none">AGO - Al
													día</span>
												<span *ngIf="comp.comp[7] === 'C'" class="d-block d-md-none">AGO -
													Cartera castigada</span>
												<span *ngIf="comp.comp[7] === 'D'" class="d-block d-md-none">AGO -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[8] === '-' || comp.comp[8] === '',
												'up-to-date': comp.comp[8] === 'N',
												'punished': comp.comp[8] === 'C',
												'dude': comp.comp[8] === 'D',
												'days-30': comp.comp[8] === '1',
												'days-60': comp.comp[8] === '2',
												'more-60-days': comp.comp[8] === '3' || comp.comp[8] === '4' || comp.comp[8] === '5' || comp.comp[8] === '6'
												}">
												<span
													*ngIf="comp.comp[8] === '1' || comp.comp[8] === '2' || comp.comp[8] === '3' || comp.comp[8] === '4' || comp.comp[8] === '5' || comp.comp[8] === '6'">{{
													comp.comp[8] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[8] === '-'" class="d-block d-md-none">SEP - Sin
													información</span>
												<span *ngIf="comp.comp[8] === 'N'" class="d-block d-md-none">SEP - Al
													día</span>
												<span *ngIf="comp.comp[8] === 'C'" class="d-block d-md-none">SEP -
													Cartera castigada</span>
												<span *ngIf="comp.comp[8] === 'D'" class="d-block d-md-none">SEP -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[9] === '-' || comp.comp[9] === '',
												'up-to-date': comp.comp[9] === 'N',
												'punished': comp.comp[9] === 'C',
												'dude': comp.comp[9] === 'D',
												'days-30': comp.comp[9] === '1',
												'days-60': comp.comp[9] === '2',
												'more-60-days': comp.comp[9] === '3' || comp.comp[9] === '4' || comp.comp[9] === '5' || comp.comp[9] === '6'
												}">
												<span
													*ngIf="comp.comp[9] === '1' || comp.comp[9] === '2' || comp.comp[9] === '3' || comp.comp[9] === '4' || comp.comp[9] === '5' || comp.comp[9] === '6'">{{
													comp.comp[9] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[9] === '-'" class="d-block d-md-none">OCT - Sin
													información</span>
												<span *ngIf="comp.comp[9] === 'N'" class="d-block d-md-none">OCT - Al
													día</span>
												<span *ngIf="comp.comp[9] === 'C'" class="d-block d-md-none">OCT -
													Cartera castigada</span>
												<span *ngIf="comp.comp[9] === 'D'" class="d-block d-md-none">OCT -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[10] === '-' || comp.comp[10] === '',
												'up-to-date': comp.comp[10] === 'N',
												'punished': comp.comp[10] === 'C',
												'dude': comp.comp[10] === 'D',
												'days-30': comp.comp[10] === '1',
												'days-60': comp.comp[10] === '2',
												'more-60-days': comp.comp[10] === '3' || comp.comp[10] === '4' || comp.comp[10] === '5' || comp.comp[10] === '6'
												}">
												<span
													*ngIf="comp.comp[10] === '1' || comp.comp[10] === '2' || comp.comp[10] === '3' || comp.comp[10] === '4' || comp.comp[10] === '5' || comp.comp[10] === '6'">{{
													comp.comp[10] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[10] === '-'" class="d-block d-md-none">NOV - Sin
													información</span>
												<span *ngIf="comp.comp[10] === 'N'" class="d-block d-md-none">NOV - Al
													día</span>
												<span *ngIf="comp.comp[10] === 'C'" class="d-block d-md-none">NOV -
													Cartera castigada</span>
												<span *ngIf="comp.comp[10] === 'D'" class="d-block d-md-none">NOV -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[11] === '-' || comp.comp[11] === '',
												'up-to-date': comp.comp[11] === 'N',
												'punished': comp.comp[11] === 'C',
												'dude': comp.comp[11] === 'D',
												'days-30': comp.comp[11] === '1',
												'days-60': comp.comp[11] === '2',
												'more-60-days': comp.comp[11] === '3' || comp.comp[11] === '4' || comp.comp[11] === '5' || comp.comp[11] === '6'
												}">
												<span
													*ngIf="comp.comp[11] === '1' || comp.comp[11] === '2' || comp.comp[11] === '3' || comp.comp[11] === '4' || comp.comp[11] === '5' || comp.comp[11] === '6'">{{
													comp.comp[11] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[11] === '-'" class="d-block d-md-none">DIC - Sin
													información</span>
												<span *ngIf="comp.comp[11] === 'N'" class="d-block d-md-none">DIC - Al
													día</span>
												<span *ngIf="comp.comp[11] === 'C'" class="d-block d-md-none">DIC -
													Cartera castigada</span>
												<span *ngIf="comp.comp[11] === 'D'" class="d-block d-md-none">DIC -
													Dudoso recaudo</span>
											</div>
										</div>
									</div>
									<div *ngIf="tabActive == 1 || tabActive1" id="tabActive1">
										<div class="tab_content"
											*ngFor="let comp of getComportSlice(1,2); let i = index">
											<div class="month anio-table">{{ comp.anio }}</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[0] === '-' || comp.comp[0] === '',
												'up-to-date': comp.comp[0] === 'N',
												'punished': comp.comp[0] === 'C',
												'dude': comp.comp[0] === 'D',
												'days-30': comp.comp[0] === '1',
												'days-60': comp.comp[0] === '2',
												'more-60-days': comp.comp[0] === '3' || comp.comp[0] === '4' || comp.comp[0] === '5' || comp.comp[0] === '6'
												}">
												<span
													*ngIf="comp.comp[0] === '1' || comp.comp[0] === '2' || comp.comp[0] === '3' || comp.comp[0] === '4' || comp.comp[0] === '5' || comp.comp[0] === '6'">{{
													comp.comp[0] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[0] === '-'" class="d-block d-md-none">ENE - Sin
													información</span>
												<span *ngIf="comp.comp[0] === 'N'" class="d-block d-md-none">ENE - Al
													día</span>
												<span *ngIf="comp.comp[0] === 'C'" class="d-block d-md-none">ENE -
													Cartera castigada</span>
												<span *ngIf="comp.comp[0] === 'D'" class="d-block d-md-none">ENE -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[1] === '-' || comp.comp[1] === '',
												'up-to-date': comp.comp[1] === 'N',
												'punished': comp.comp[1] === 'C',
												'dude': comp.comp[1] === 'D',
												'days-30': comp.comp[1] === '1',
												'days-60': comp.comp[1] === '2',
												'more-60-days': comp.comp[1] === '3' || comp.comp[1] === '4' || comp.comp[1] === '5' || comp.comp[1] === '6'
												}">
												<span
													*ngIf="comp.comp[1] === '1' || comp.comp[1] === '2' || comp.comp[1] === '3' || comp.comp[1] === '4' || comp.comp[1] === '5' || comp.comp[1] === '6'">{{
													comp.comp[1] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[1] === '-'" class="d-block d-md-none">FEB - Sin
													información</span>
												<span *ngIf="comp.comp[1] === 'N'" class="d-block d-md-none">FEB - Al
													día</span>
												<span *ngIf="comp.comp[1] === 'C'" class="d-block d-md-none">FEB -
													Cartera castigada</span>
												<span *ngIf="comp.comp[1] === 'D'" class="d-block d-md-none">FEB -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[2] === '-' || comp.comp[2] === '',
												'up-to-date': comp.comp[2] === 'N',
												'punished': comp.comp[2] === 'C',
												'dude': comp.comp[2] === 'D',
												'days-30': comp.comp[2] === '1',
												'days-60': comp.comp[2] === '2',
												'more-60-days': comp.comp[2] === '3' || comp.comp[2] === '4' || comp.comp[2] === '5' || comp.comp[2] === '6'
												}">
												<span
													*ngIf="comp.comp[2] === '1' || comp.comp[2] === '2' || comp.comp[2] === '3' || comp.comp[2] === '4' || comp.comp[2] === '5' || comp.comp[2] === '6'">{{
													comp.comp[2] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[2] === '-'" class="d-block d-md-none">MAR - Sin
													información</span>
												<span *ngIf="comp.comp[2] === 'N'" class="d-block d-md-none">MAR - Al
													día</span>
												<span *ngIf="comp.comp[2] === 'C'" class="d-block d-md-none">MAR -
													Cartera castigada</span>
												<span *ngIf="comp.comp[2] === 'D'" class="d-block d-md-none">MAR -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[3] === '-' || comp.comp[3] === '',
												'up-to-date': comp.comp[3] === 'N',
												'punished': comp.comp[3] === 'C',
												'dude': comp.comp[3] === 'D',
												'days-30': comp.comp[3] === '1',
												'days-60': comp.comp[3] === '2',
												'more-60-days': comp.comp[3] === '3' || comp.comp[3] === '4' || comp.comp[3] === '5' || comp.comp[3] === '6'
												}">
												<span
													*ngIf="comp.comp[3] === '1' || comp.comp[3] === '2' || comp.comp[3] === '3' || comp.comp[3] === '4' || comp.comp[3] === '5' || comp.comp[3] === '6'">{{
													comp.comp[3] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[3] === '-'" class="d-block d-md-none">ABR - Sin
													información</span>
												<span *ngIf="comp.comp[3] === 'N'" class="d-block d-md-none">ABR - Al
													día</span>
												<span *ngIf="comp.comp[3] === 'C'" class="d-block d-md-none">ABR -
													Cartera castigada</span>
												<span *ngIf="comp.comp[3] === 'D'" class="d-block d-md-none">ABR -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[4] === '-' || comp.comp[4] === '',
												'up-to-date': comp.comp[4] === 'N',
												'punished': comp.comp[4] === 'C',
												'dude': comp.comp[4] === 'D',
												'days-30': comp.comp[4] === '1',
												'days-60': comp.comp[4] === '2',
												'more-60-days': comp.comp[4] === '3' || comp.comp[4] === '4' || comp.comp[4] === '5' || comp.comp[4] === '6'
												}">
												<span
													*ngIf="comp.comp[4] === '1' || comp.comp[4] === '2' || comp.comp[4] === '3' || comp.comp[4] === '4' || comp.comp[4] === '5' || comp.comp[4] === '6'">{{
													comp.comp[4] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[4] === '-'" class="d-block d-md-none">MAY - Sin
													información</span>
												<span *ngIf="comp.comp[4] === 'N'" class="d-block d-md-none">MAY - Al
													día</span>
												<span *ngIf="comp.comp[4] === 'C'" class="d-block d-md-none">MAY -
													Cartera castigada</span>
												<span *ngIf="comp.comp[4] === 'D'" class="d-block d-md-none">MAY -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[5] === '-' || comp.comp[5] === '',
												'up-to-date': comp.comp[5] === 'N',
												'punished': comp.comp[5] === 'C',
												'dude': comp.comp[5] === 'D',
												'days-30': comp.comp[5] === '1',
												'days-60': comp.comp[5] === '2',
												'more-60-days': comp.comp[5] === '3' || comp.comp[5] === '4' || comp.comp[5] === '5' || comp.comp[5] === '6'
												}">
												<span
													*ngIf="comp.comp[5] === '1' || comp.comp[5] === '2' || comp.comp[5] === '3' || comp.comp[5] === '4' || comp.comp[5] === '5' || comp.comp[5] === '6'">{{
													comp.comp[5] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[5] === '-'" class="d-block d-md-none">JUN - Sin
													información</span>
												<span *ngIf="comp.comp[5] === 'N'" class="d-block d-md-none">JUN - Al
													día</span>
												<span *ngIf="comp.comp[5] === 'C'" class="d-block d-md-none">JUN -
													Cartera castigada</span>
												<span *ngIf="comp.comp[5] === 'D'" class="d-block d-md-none">JUN -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[6] === '-' || comp.comp[6] === '',
												'up-to-date': comp.comp[6] === 'N',
												'punished': comp.comp[6] === 'C',
												'dude': comp.comp[6] === 'D',
												'days-30': comp.comp[6] === '1',
												'days-60': comp.comp[6] === '2',
												'more-60-days': comp.comp[6] === '3' || comp.comp[6] === '4' || comp.comp[6] === '5' || comp.comp[6] === '6'
												}">
												<span
													*ngIf="comp.comp[6] === '1' || comp.comp[6] === '2' || comp.comp[6] === '3' || comp.comp[6] === '4' || comp.comp[6] === '5' || comp.comp[6] === '6'">{{
													comp.comp[6] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[6] === '-'" class="d-block d-md-none">JUL - Sin
													información</span>
												<span *ngIf="comp.comp[6] === 'N'" class="d-block d-md-none">JUL - Al
													día</span>
												<span *ngIf="comp.comp[6] === 'C'" class="d-block d-md-none">JUL -
													Cartera castigada</span>
												<span *ngIf="comp.comp[6] === 'D'" class="d-block d-md-none">JUL -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[7] === '-' || comp.comp[7] === '',
												'up-to-date': comp.comp[7] === 'N',
												'punished': comp.comp[7] === 'C',
												'dude': comp.comp[7] === 'D',
												'days-30': comp.comp[7] === '1',
												'days-60': comp.comp[7] === '2',
												'more-60-days': comp.comp[7] === '3' || comp.comp[7] === '4' || comp.comp[7] === '5' || comp.comp[7] === '6'
												}">
												<span
													*ngIf="comp.comp[7] === '1' || comp.comp[7] === '2' || comp.comp[7] === '3' || comp.comp[7] === '4' || comp.comp[7] === '5' || comp.comp[7] === '6'">{{
													comp.comp[7] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[7] === '-'" class="d-block d-md-none">AGO - Sin
													información</span>
												<span *ngIf="comp.comp[7] === 'N'" class="d-block d-md-none">AGO - Al
													día</span>
												<span *ngIf="comp.comp[7] === 'C'" class="d-block d-md-none">AGO -
													Cartera castigada</span>
												<span *ngIf="comp.comp[7] === 'D'" class="d-block d-md-none">AGO -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[8] === '-' || comp.comp[8] === '',
												'up-to-date': comp.comp[8] === 'N',
												'punished': comp.comp[8] === 'C',
												'dude': comp.comp[8] === 'D',
												'days-30': comp.comp[8] === '1',
												'days-60': comp.comp[8] === '2',
												'more-60-days': comp.comp[8] === '3' || comp.comp[8] === '4' || comp.comp[8] === '5' || comp.comp[8] === '6'
												}">
												<span
													*ngIf="comp.comp[8] === '1' || comp.comp[8] === '2' || comp.comp[8] === '3' || comp.comp[8] === '4' || comp.comp[8] === '5' || comp.comp[8] === '6'">{{
													comp.comp[8] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[8] === '-'" class="d-block d-md-none">SEP - Sin
													información</span>
												<span *ngIf="comp.comp[8] === 'N'" class="d-block d-md-none">SEP - Al
													día</span>
												<span *ngIf="comp.comp[8] === 'C'" class="d-block d-md-none">SEP -
													Cartera castigada</span>
												<span *ngIf="comp.comp[8] === 'D'" class="d-block d-md-none">SEP -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[9] === '-' || comp.comp[9] === '',
												'up-to-date': comp.comp[9] === 'N',
												'punished': comp.comp[9] === 'C',
												'dude': comp.comp[9] === 'D',
												'days-30': comp.comp[9] === '1',
												'days-60': comp.comp[9] === '2',
												'more-60-days': comp.comp[9] === '3' || comp.comp[9] === '4' || comp.comp[9] === '5' || comp.comp[9] === '6'
												}">
												<span
													*ngIf="comp.comp[9] === '1' || comp.comp[9] === '2' || comp.comp[9] === '3' || comp.comp[9] === '4' || comp.comp[9] === '5' || comp.comp[9] === '6'">{{
													comp.comp[9] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[9] === '-'" class="d-block d-md-none">OCT - Sin
													información</span>
												<span *ngIf="comp.comp[9] === 'N'" class="d-block d-md-none">OCT - Al
													día</span>
												<span *ngIf="comp.comp[9] === 'C'" class="d-block d-md-none">OCT -
													Cartera castigada</span>
												<span *ngIf="comp.comp[9] === 'D'" class="d-block d-md-none">OCT -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[10] === '-' || comp.comp[10] === '',
												'up-to-date': comp.comp[10] === 'N',
												'punished': comp.comp[10] === 'C',
												'dude': comp.comp[10] === 'D',
												'days-30': comp.comp[10] === '1',
												'days-60': comp.comp[10] === '2',
												'more-60-days': comp.comp[10] === '3' || comp.comp[10] === '4' || comp.comp[10] === '5' || comp.comp[10] === '6'
												}">
												<span
													*ngIf="comp.comp[10] === '1' || comp.comp[10] === '2' || comp.comp[10] === '3' || comp.comp[10] === '4' || comp.comp[10] === '5' || comp.comp[10] === '6'">{{
													comp.comp[10] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[10] === '-'" class="d-block d-md-none">NOV - Sin
													información</span>
												<span *ngIf="comp.comp[10] === 'N'" class="d-block d-md-none">NOV - Al
													día</span>
												<span *ngIf="comp.comp[10] === 'C'" class="d-block d-md-none">NOV -
													Cartera castigada</span>
												<span *ngIf="comp.comp[10] === 'D'" class="d-block d-md-none">NOV -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[11] === '-' || comp.comp[11] === '',
												'up-to-date': comp.comp[11] === 'N',
												'punished': comp.comp[11] === 'C',
												'dude': comp.comp[11] === 'D',
												'days-30': comp.comp[11] === '1',
												'days-60': comp.comp[11] === '2',
												'more-60-days': comp.comp[11] === '3' || comp.comp[11] === '4' || comp.comp[11] === '5' || comp.comp[11] === '6'
												}">
												<span
													*ngIf="comp.comp[11] === '1' || comp.comp[11] === '2' || comp.comp[11] === '3' || comp.comp[11] === '4' || comp.comp[11] === '5' || comp.comp[11] === '6'">{{
													comp.comp[11] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[11] === '-'" class="d-block d-md-none">DIC - Sin
													información</span>
												<span *ngIf="comp.comp[11] === 'N'" class="d-block d-md-none">DIC - Al
													día</span>
												<span *ngIf="comp.comp[11] === 'C'" class="d-block d-md-none">DIC -
													Cartera castigada</span>
												<span *ngIf="comp.comp[11] === 'D'" class="d-block d-md-none">DIC -
													Dudoso recaudo</span>
											</div>
										</div>
									</div>
									<div *ngIf="tabActive == 2 || tabActive2" id="tabActive2">
										<div class="tab_content"
											*ngFor="let comp of getComportSlice(2,3); let i = index">
											<div class="month anio-table">{{ comp.anio }}</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[0] === '-' || comp.comp[0] === '',
												'up-to-date': comp.comp[0] === 'N',
												'punished': comp.comp[0] === 'C',
												'dude': comp.comp[0] === 'D',
												'days-30': comp.comp[0] === '1',
												'days-60': comp.comp[0] === '2',
												'more-60-days': comp.comp[0] === '3' || comp.comp[0] === '4' || comp.comp[0] === '5' || comp.comp[0] === '6'
												}">
												<span
													*ngIf="comp.comp[0] === '1' || comp.comp[0] === '2' || comp.comp[0] === '3' || comp.comp[0] === '4' || comp.comp[0] === '5' || comp.comp[0] === '6'">{{
													comp.comp[0] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[0] === '-'" class="d-block d-md-none">ENE - Sin
													información</span>
												<span *ngIf="comp.comp[0] === 'N'" class="d-block d-md-none">ENE - Al
													día</span>
												<span *ngIf="comp.comp[0] === 'C'" class="d-block d-md-none">ENE -
													Cartera castigada</span>
												<span *ngIf="comp.comp[0] === 'D'" class="d-block d-md-none">ENE -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[1] === '-' || comp.comp[1] === '',
												'up-to-date': comp.comp[1] === 'N',
												'punished': comp.comp[1] === 'C',
												'dude': comp.comp[1] === 'D',
												'days-30': comp.comp[1] === '1',
												'days-60': comp.comp[1] === '2',
												'more-60-days': comp.comp[1] === '3' || comp.comp[1] === '4' || comp.comp[1] === '5' || comp.comp[1] === '6'
												}">
												<span
													*ngIf="comp.comp[1] === '1' || comp.comp[1] === '2' || comp.comp[1] === '3' || comp.comp[1] === '4' || comp.comp[1] === '5' || comp.comp[1] === '6'">{{
													comp.comp[1] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[1] === '-'" class="d-block d-md-none">FEB - Sin
													información</span>
												<span *ngIf="comp.comp[1] === 'N'" class="d-block d-md-none">FEB - Al
													día</span>
												<span *ngIf="comp.comp[1] === 'C'" class="d-block d-md-none">FEB -
													Cartera castigada</span>
												<span *ngIf="comp.comp[1] === 'D'" class="d-block d-md-none">FEB -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[2] === '-' || comp.comp[2] === '',
												'up-to-date': comp.comp[2] === 'N',
												'punished': comp.comp[2] === 'C',
												'dude': comp.comp[2] === 'D',
												'days-30': comp.comp[2] === '1',
												'days-60': comp.comp[2] === '2',
												'more-60-days': comp.comp[2] === '3' || comp.comp[2] === '4' || comp.comp[2] === '5' || comp.comp[2] === '6'
												}">
												<span
													*ngIf="comp.comp[2] === '1' || comp.comp[2] === '2' || comp.comp[2] === '3' || comp.comp[2] === '4' || comp.comp[2] === '5' || comp.comp[2] === '6'">{{
													comp.comp[2] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[2] === '-'" class="d-block d-md-none">MAR - Sin
													información</span>
												<span *ngIf="comp.comp[2] === 'N'" class="d-block d-md-none">MAR - Al
													día</span>
												<span *ngIf="comp.comp[2] === 'C'" class="d-block d-md-none">MAR -
													Cartera castigada</span>
												<span *ngIf="comp.comp[2] === 'D'" class="d-block d-md-none">MAR -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[3] === '-' || comp.comp[3] === '',
												'up-to-date': comp.comp[3] === 'N',
												'punished': comp.comp[3] === 'C',
												'dude': comp.comp[3] === 'D',
												'days-30': comp.comp[3] === '1',
												'days-60': comp.comp[3] === '2',
												'more-60-days': comp.comp[3] === '3' || comp.comp[3] === '4' || comp.comp[3] === '5' || comp.comp[3] === '6'
												}">
												<span
													*ngIf="comp.comp[3] === '1' || comp.comp[3] === '2' || comp.comp[3] === '3' || comp.comp[3] === '4' || comp.comp[3] === '5' || comp.comp[3] === '6'">{{
													comp.comp[3] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[3] === '-'" class="d-block d-md-none">ABR - Sin
													información</span>
												<span *ngIf="comp.comp[3] === 'N'" class="d-block d-md-none">ABR - Al
													día</span>
												<span *ngIf="comp.comp[3] === 'C'" class="d-block d-md-none">ABR -
													Cartera castigada</span>
												<span *ngIf="comp.comp[3] === 'D'" class="d-block d-md-none">ABR -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[4] === '-' || comp.comp[4] === '',
												'up-to-date': comp.comp[4] === 'N',
												'punished': comp.comp[4] === 'C',
												'dude': comp.comp[4] === 'D',
												'days-30': comp.comp[4] === '1',
												'days-60': comp.comp[4] === '2',
												'more-60-days': comp.comp[4] === '3' || comp.comp[4] === '4' || comp.comp[4] === '5' || comp.comp[4] === '6'
												}">
												<span
													*ngIf="comp.comp[4] === '1' || comp.comp[4] === '2' || comp.comp[4] === '3' || comp.comp[4] === '4' || comp.comp[4] === '5' || comp.comp[4] === '6'">{{
													comp.comp[4] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[4] === '-'" class="d-block d-md-none">MAY - Sin
													información</span>
												<span *ngIf="comp.comp[4] === 'N'" class="d-block d-md-none">MAY - Al
													día</span>
												<span *ngIf="comp.comp[4] === 'C'" class="d-block d-md-none">MAY -
													Cartera castigada</span>
												<span *ngIf="comp.comp[4] === 'D'" class="d-block d-md-none">MAY -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[5] === '-' || comp.comp[5] === '',
												'up-to-date': comp.comp[5] === 'N',
												'punished': comp.comp[5] === 'C',
												'dude': comp.comp[5] === 'D',
												'days-30': comp.comp[5] === '1',
												'days-60': comp.comp[5] === '2',
												'more-60-days': comp.comp[5] === '3' || comp.comp[5] === '4' || comp.comp[5] === '5' || comp.comp[5] === '6'
												}">
												<span
													*ngIf="comp.comp[5] === '1' || comp.comp[5] === '2' || comp.comp[5] === '3' || comp.comp[5] === '4' || comp.comp[5] === '5' || comp.comp[5] === '6'">{{
													comp.comp[5] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[5] === '-'" class="d-block d-md-none">JUN - Sin
													información</span>
												<span *ngIf="comp.comp[5] === 'N'" class="d-block d-md-none">JUN - Al
													día</span>
												<span *ngIf="comp.comp[5] === 'C'" class="d-block d-md-none">JUN -
													Cartera castigada</span>
												<span *ngIf="comp.comp[5] === 'D'" class="d-block d-md-none">JUN -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[6] === '-' || comp.comp[6] === '',
												'up-to-date': comp.comp[6] === 'N',
												'punished': comp.comp[6] === 'C',
												'dude': comp.comp[6] === 'D',
												'days-30': comp.comp[6] === '1',
												'days-60': comp.comp[6] === '2',
												'more-60-days': comp.comp[6] === '3' || comp.comp[6] === '4' || comp.comp[6] === '5' || comp.comp[6] === '6'
												}">
												<span
													*ngIf="comp.comp[6] === '1' || comp.comp[6] === '2' || comp.comp[6] === '3' || comp.comp[6] === '4' || comp.comp[6] === '5' || comp.comp[6] === '6'">{{
													comp.comp[6] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[6] === '-'" class="d-block d-md-none">JUL - Sin
													información</span>
												<span *ngIf="comp.comp[6] === 'N'" class="d-block d-md-none">JUL - Al
													día</span>
												<span *ngIf="comp.comp[6] === 'C'" class="d-block d-md-none">JUL -
													Cartera castigada</span>
												<span *ngIf="comp.comp[6] === 'D'" class="d-block d-md-none">JUL -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[7] === '-' || comp.comp[7] === '',
												'up-to-date': comp.comp[7] === 'N',
												'punished': comp.comp[7] === 'C',
												'dude': comp.comp[7] === 'D',
												'days-30': comp.comp[7] === '1',
												'days-60': comp.comp[7] === '2',
												'more-60-days': comp.comp[7] === '3' || comp.comp[7] === '4' || comp.comp[7] === '5' || comp.comp[7] === '6'
												}">
												<span
													*ngIf="comp.comp[7] === '1' || comp.comp[7] === '2' || comp.comp[7] === '3' || comp.comp[7] === '4' || comp.comp[7] === '5' || comp.comp[7] === '6'">{{
													comp.comp[7] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[7] === '-'" class="d-block d-md-none">AGO - Sin
													información</span>
												<span *ngIf="comp.comp[7] === 'N'" class="d-block d-md-none">AGO - Al
													día</span>
												<span *ngIf="comp.comp[7] === 'C'" class="d-block d-md-none">AGO -
													Cartera castigada</span>
												<span *ngIf="comp.comp[7] === 'D'" class="d-block d-md-none">AGO -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[8] === '-' || comp.comp[8] === '',
												'up-to-date': comp.comp[8] === 'N',
												'punished': comp.comp[8] === 'C',
												'dude': comp.comp[8] === 'D',
												'days-30': comp.comp[8] === '1',
												'days-60': comp.comp[8] === '2',
												'more-60-days': comp.comp[8] === '3' || comp.comp[8] === '4' || comp.comp[8] === '5' || comp.comp[8] === '6'
												}">
												<span
													*ngIf="comp.comp[8] === '1' || comp.comp[8] === '2' || comp.comp[8] === '3' || comp.comp[8] === '4' || comp.comp[8] === '5' || comp.comp[8] === '6'">{{
													comp.comp[8] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[8] === '-'" class="d-block d-md-none">SEP - Sin
													información</span>
												<span *ngIf="comp.comp[8] === 'N'" class="d-block d-md-none">SEP - Al
													día</span>
												<span *ngIf="comp.comp[8] === 'C'" class="d-block d-md-none">SEP -
													Cartera castigada</span>
												<span *ngIf="comp.comp[8] === 'D'" class="d-block d-md-none">SEP -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[9] === '-' || comp.comp[9] === '',
												'up-to-date': comp.comp[9] === 'N',
												'punished': comp.comp[9] === 'C',
												'dude': comp.comp[9] === 'D',
												'days-30': comp.comp[9] === '1',
												'days-60': comp.comp[9] === '2',
												'more-60-days': comp.comp[9] === '3' || comp.comp[9] === '4' || comp.comp[9] === '5' || comp.comp[9] === '6'
												}">
												<span
													*ngIf="comp.comp[9] === '1' || comp.comp[9] === '2' || comp.comp[9] === '3' || comp.comp[9] === '4' || comp.comp[9] === '5' || comp.comp[9] === '6'">{{
													comp.comp[9] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[9] === '-'" class="d-block d-md-none">OCT - Sin
													información</span>
												<span *ngIf="comp.comp[9] === 'N'" class="d-block d-md-none">OCT - Al
													día</span>
												<span *ngIf="comp.comp[9] === 'C'" class="d-block d-md-none">OCT -
													Cartera castigada</span>
												<span *ngIf="comp.comp[9] === 'D'" class="d-block d-md-none">OCT -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[10] === '-' || comp.comp[10] === '',
												'up-to-date': comp.comp[10] === 'N',
												'punished': comp.comp[10] === 'C',
												'dude': comp.comp[10] === 'D',
												'days-30': comp.comp[10] === '1',
												'days-60': comp.comp[10] === '2',
												'more-60-days': comp.comp[10] === '3' || comp.comp[10] === '4' || comp.comp[10] === '5' || comp.comp[10] === '6'
												}">
												<span
													*ngIf="comp.comp[10] === '1' || comp.comp[10] === '2' || comp.comp[10] === '3' || comp.comp[10] === '4' || comp.comp[10] === '5' || comp.comp[10] === '6'">{{
													comp.comp[10] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[10] === '-'" class="d-block d-md-none">NOV - Sin
													información</span>
												<span *ngIf="comp.comp[10] === 'N'" class="d-block d-md-none">NOV - Al
													día</span>
												<span *ngIf="comp.comp[10] === 'C'" class="d-block d-md-none">NOV -
													Cartera castigada</span>
												<span *ngIf="comp.comp[10] === 'D'" class="d-block d-md-none">NOV -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[11] === '-' || comp.comp[11] === '',
												'up-to-date': comp.comp[11] === 'N',
												'punished': comp.comp[11] === 'C',
												'dude': comp.comp[11] === 'D',
												'days-30': comp.comp[11] === '1',
												'days-60': comp.comp[11] === '2',
												'more-60-days': comp.comp[11] === '3' || comp.comp[11] === '4' || comp.comp[11] === '5' || comp.comp[11] === '6'
												}">
												<span
													*ngIf="comp.comp[11] === '1' || comp.comp[11] === '2' || comp.comp[11] === '3' || comp.comp[11] === '4' || comp.comp[11] === '5' || comp.comp[11] === '6'">{{
													comp.comp[11] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[11] === '-'" class="d-block d-md-none">DIC - Sin
													información</span>
												<span *ngIf="comp.comp[11] === 'N'" class="d-block d-md-none">DIC - Al
													día</span>
												<span *ngIf="comp.comp[11] === 'C'" class="d-block d-md-none">DIC -
													Cartera castigada</span>
												<span *ngIf="comp.comp[11] === 'D'" class="d-block d-md-none">DIC -
													Dudoso recaudo</span>
											</div>
										</div>
									</div>
									<div *ngIf="tabActive == 3 || tabActive3" id="tabActive3">
										<div class="tab_content"
											*ngFor="let comp of getComportSlice(3,4); let i = index">
											<div class="month anio-table">{{ comp.anio }}</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[0] === '-' || comp.comp[0] === '',
												'up-to-date': comp.comp[0] === 'N',
												'punished': comp.comp[0] === 'C',
												'dude': comp.comp[0] === 'D',
												'days-30': comp.comp[0] === '1',
												'days-60': comp.comp[0] === '2',
												'more-60-days': comp.comp[0] === '3' || comp.comp[0] === '4' || comp.comp[0] === '5' || comp.comp[0] === '6'
												}">
												<span
													*ngIf="comp.comp[0] === '1' || comp.comp[0] === '2' || comp.comp[0] === '3' || comp.comp[0] === '4' || comp.comp[0] === '5' || comp.comp[0] === '6'">{{
													comp.comp[0] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[0] === '-'" class="d-block d-md-none">ENE - Sin
													información</span>
												<span *ngIf="comp.comp[0] === 'N'" class="d-block d-md-none">ENE - Al
													día</span>
												<span *ngIf="comp.comp[0] === 'C'" class="d-block d-md-none">ENE -
													Cartera castigada</span>
												<span *ngIf="comp.comp[0] === 'D'" class="d-block d-md-none">ENE -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[1] === '-' || comp.comp[1] === '',
												'up-to-date': comp.comp[1] === 'N',
												'punished': comp.comp[1] === 'C',
												'dude': comp.comp[1] === 'D',
												'days-30': comp.comp[1] === '1',
												'days-60': comp.comp[1] === '2',
												'more-60-days': comp.comp[1] === '3' || comp.comp[1] === '4' || comp.comp[1] === '5' || comp.comp[1] === '6'
												}">
												<span
													*ngIf="comp.comp[1] === '1' || comp.comp[1] === '2' || comp.comp[1] === '3' || comp.comp[1] === '4' || comp.comp[1] === '5' || comp.comp[1] === '6'">{{
													comp.comp[1] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[1] === '-'" class="d-block d-md-none">FEB - Sin
													información</span>
												<span *ngIf="comp.comp[1] === 'N'" class="d-block d-md-none">FEB - Al
													día</span>
												<span *ngIf="comp.comp[1] === 'C'" class="d-block d-md-none">FEB -
													Cartera castigada</span>
												<span *ngIf="comp.comp[1] === 'D'" class="d-block d-md-none">FEB -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[2] === '-' || comp.comp[2] === '',
												'up-to-date': comp.comp[2] === 'N',
												'punished': comp.comp[2] === 'C',
												'dude': comp.comp[2] === 'D',
												'days-30': comp.comp[2] === '1',
												'days-60': comp.comp[2] === '2',
												'more-60-days': comp.comp[2] === '3' || comp.comp[2] === '4' || comp.comp[2] === '5' || comp.comp[2] === '6'
												}">
												<span
													*ngIf="comp.comp[2] === '1' || comp.comp[2] === '2' || comp.comp[2] === '3' || comp.comp[2] === '4' || comp.comp[2] === '5' || comp.comp[2] === '6'">{{
													comp.comp[2] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[2] === '-'" class="d-block d-md-none">MAR - Sin
													información</span>
												<span *ngIf="comp.comp[2] === 'N'" class="d-block d-md-none">MAR - Al
													día</span>
												<span *ngIf="comp.comp[2] === 'C'" class="d-block d-md-none">MAR -
													Cartera castigada</span>
												<span *ngIf="comp.comp[2] === 'D'" class="d-block d-md-none">MAR -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[3] === '-' || comp.comp[3] === '',
												'up-to-date': comp.comp[3] === 'N',
												'punished': comp.comp[3] === 'C',
												'dude': comp.comp[3] === 'D',
												'days-30': comp.comp[3] === '1',
												'days-60': comp.comp[3] === '2',
												'more-60-days': comp.comp[3] === '3' || comp.comp[3] === '4' || comp.comp[3] === '5' || comp.comp[3] === '6'
												}">
												<span
													*ngIf="comp.comp[3] === '1' || comp.comp[3] === '2' || comp.comp[3] === '3' || comp.comp[3] === '4' || comp.comp[3] === '5' || comp.comp[3] === '6'">{{
													comp.comp[3] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[3] === '-'" class="d-block d-md-none">ABR - Sin
													información</span>
												<span *ngIf="comp.comp[3] === 'N'" class="d-block d-md-none">ABR - Al
													día</span>
												<span *ngIf="comp.comp[3] === 'C'" class="d-block d-md-none">ABR -
													Cartera castigada</span>
												<span *ngIf="comp.comp[3] === 'D'" class="d-block d-md-none">ABR -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[4] === '-' || comp.comp[4] === '',
												'up-to-date': comp.comp[4] === 'N',
												'punished': comp.comp[4] === 'C',
												'dude': comp.comp[4] === 'D',
												'days-30': comp.comp[4] === '1',
												'days-60': comp.comp[4] === '2',
												'more-60-days': comp.comp[4] === '3' || comp.comp[4] === '4' || comp.comp[4] === '5' || comp.comp[4] === '6'
												}">
												<span
													*ngIf="comp.comp[4] === '1' || comp.comp[4] === '2' || comp.comp[4] === '3' || comp.comp[4] === '4' || comp.comp[4] === '5' || comp.comp[4] === '6'">{{
													comp.comp[4] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[4] === '-'" class="d-block d-md-none">MAY - Sin
													información</span>
												<span *ngIf="comp.comp[4] === 'N'" class="d-block d-md-none">MAY - Al
													día</span>
												<span *ngIf="comp.comp[4] === 'C'" class="d-block d-md-none">MAY -
													Cartera castigada</span>
												<span *ngIf="comp.comp[4] === 'D'" class="d-block d-md-none">MAY -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[5] === '-' || comp.comp[5] === '',
												'up-to-date': comp.comp[5] === 'N',
												'punished': comp.comp[5] === 'C',
												'dude': comp.comp[5] === 'D',
												'days-30': comp.comp[5] === '1',
												'days-60': comp.comp[5] === '2',
												'more-60-days': comp.comp[5] === '3' || comp.comp[5] === '4' || comp.comp[5] === '5' || comp.comp[5] === '6'
												}">
												<span
													*ngIf="comp.comp[5] === '1' || comp.comp[5] === '2' || comp.comp[5] === '3' || comp.comp[5] === '4' || comp.comp[5] === '5' || comp.comp[5] === '6'">{{
													comp.comp[5] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[5] === '-'" class="d-block d-md-none">JUN - Sin
													información</span>
												<span *ngIf="comp.comp[5] === 'N'" class="d-block d-md-none">JUN - Al
													día</span>
												<span *ngIf="comp.comp[5] === 'C'" class="d-block d-md-none">JUN -
													Cartera castigada</span>
												<span *ngIf="comp.comp[5] === 'D'" class="d-block d-md-none">JUN -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[6] === '-' || comp.comp[6] === '',
												'up-to-date': comp.comp[6] === 'N',
												'punished': comp.comp[6] === 'C',
												'dude': comp.comp[6] === 'D',
												'days-30': comp.comp[6] === '1',
												'days-60': comp.comp[6] === '2',
												'more-60-days': comp.comp[6] === '3' || comp.comp[6] === '4' || comp.comp[6] === '5' || comp.comp[6] === '6'
												}">
												<span
													*ngIf="comp.comp[6] === '1' || comp.comp[6] === '2' || comp.comp[6] === '3' || comp.comp[6] === '4' || comp.comp[6] === '5' || comp.comp[6] === '6'">{{
													comp.comp[6] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[6] === '-'" class="d-block d-md-none">JUL - Sin
													información</span>
												<span *ngIf="comp.comp[6] === 'N'" class="d-block d-md-none">JUL - Al
													día</span>
												<span *ngIf="comp.comp[6] === 'C'" class="d-block d-md-none">JUL -
													Cartera castigada</span>
												<span *ngIf="comp.comp[6] === 'D'" class="d-block d-md-none">JUL -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[7] === '-' || comp.comp[7] === '',
												'up-to-date': comp.comp[7] === 'N',
												'punished': comp.comp[7] === 'C',
												'dude': comp.comp[7] === 'D',
												'days-30': comp.comp[7] === '1',
												'days-60': comp.comp[7] === '2',
												'more-60-days': comp.comp[7] === '3' || comp.comp[7] === '4' || comp.comp[7] === '5' || comp.comp[7] === '6'
												}">
												<span
													*ngIf="comp.comp[7] === '1' || comp.comp[7] === '2' || comp.comp[7] === '3' || comp.comp[7] === '4' || comp.comp[7] === '5' || comp.comp[7] === '6'">{{
													comp.comp[7] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[7] === '-'" class="d-block d-md-none">AGO - Sin
													información</span>
												<span *ngIf="comp.comp[7] === 'N'" class="d-block d-md-none">AGO - Al
													día</span>
												<span *ngIf="comp.comp[7] === 'C'" class="d-block d-md-none">AGO -
													Cartera castigada</span>
												<span *ngIf="comp.comp[7] === 'D'" class="d-block d-md-none">AGO -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[8] === '-' || comp.comp[8] === '',
												'up-to-date': comp.comp[8] === 'N',
												'punished': comp.comp[8] === 'C',
												'dude': comp.comp[8] === 'D',
												'days-30': comp.comp[8] === '1',
												'days-60': comp.comp[8] === '2',
												'more-60-days': comp.comp[8] === '3' || comp.comp[8] === '4' || comp.comp[8] === '5' || comp.comp[8] === '6'
												}">
												<span
													*ngIf="comp.comp[8] === '1' || comp.comp[8] === '2' || comp.comp[8] === '3' || comp.comp[8] === '4' || comp.comp[8] === '5' || comp.comp[8] === '6'">{{
													comp.comp[8] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[8] === '-'" class="d-block d-md-none">SEP - Sin
													información</span>
												<span *ngIf="comp.comp[8] === 'N'" class="d-block d-md-none">SEP - Al
													día</span>
												<span *ngIf="comp.comp[8] === 'C'" class="d-block d-md-none">SEP -
													Cartera castigada</span>
												<span *ngIf="comp.comp[8] === 'D'" class="d-block d-md-none">SEP -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[9] === '-' || comp.comp[9] === '',
												'up-to-date': comp.comp[9] === 'N',
												'punished': comp.comp[9] === 'C',
												'dude': comp.comp[9] === 'D',
												'days-30': comp.comp[9] === '1',
												'days-60': comp.comp[9] === '2',
												'more-60-days': comp.comp[9] === '3' || comp.comp[9] === '4' || comp.comp[9] === '5' || comp.comp[9] === '6'
												}">
												<span
													*ngIf="comp.comp[9] === '1' || comp.comp[9] === '2' || comp.comp[9] === '3' || comp.comp[9] === '4' || comp.comp[9] === '5' || comp.comp[9] === '6'">{{
													comp.comp[9] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[9] === '-'" class="d-block d-md-none">OCT - Sin
													información</span>
												<span *ngIf="comp.comp[9] === 'N'" class="d-block d-md-none">OCT - Al
													día</span>
												<span *ngIf="comp.comp[9] === 'C'" class="d-block d-md-none">OCT -
													Cartera castigada</span>
												<span *ngIf="comp.comp[9] === 'D'" class="d-block d-md-none">OCT -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[10] === '-' || comp.comp[10] === '',
												'up-to-date': comp.comp[10] === 'N',
												'punished': comp.comp[10] === 'C',
												'dude': comp.comp[10] === 'D',
												'days-30': comp.comp[10] === '1',
												'days-60': comp.comp[10] === '2',
												'more-60-days': comp.comp[10] === '3' || comp.comp[10] === '4' || comp.comp[10] === '5' || comp.comp[10] === '6'
												}">
												<span
													*ngIf="comp.comp[10] === '1' || comp.comp[10] === '2' || comp.comp[10] === '3' || comp.comp[10] === '4' || comp.comp[10] === '5' || comp.comp[10] === '6'">{{
													comp.comp[10] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[10] === '-'" class="d-block d-md-none">NOV - Sin
													información</span>
												<span *ngIf="comp.comp[10] === 'N'" class="d-block d-md-none">NOV - Al
													día</span>
												<span *ngIf="comp.comp[10] === 'C'" class="d-block d-md-none">NOV -
													Cartera castigada</span>
												<span *ngIf="comp.comp[10] === 'D'" class="d-block d-md-none">NOV -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[11] === '-' || comp.comp[11] === '',
												'up-to-date': comp.comp[11] === 'N',
												'punished': comp.comp[11] === 'C',
												'dude': comp.comp[11] === 'D',
												'days-30': comp.comp[11] === '1',
												'days-60': comp.comp[11] === '2',
												'more-60-days': comp.comp[11] === '3' || comp.comp[11] === '4' || comp.comp[11] === '5' || comp.comp[11] === '6'
												}">
												<span
													*ngIf="comp.comp[11] === '1' || comp.comp[11] === '2' || comp.comp[11] === '3' || comp.comp[11] === '4' || comp.comp[11] === '5' || comp.comp[11] === '6'">{{
													comp.comp[11] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[11] === '-'" class="d-block d-md-none">DIC - Sin
													información</span>
												<span *ngIf="comp.comp[11] === 'N'" class="d-block d-md-none">DIC - Al
													día</span>
												<span *ngIf="comp.comp[11] === 'C'" class="d-block d-md-none">DIC -
													Cartera castigada</span>
												<span *ngIf="comp.comp[11] === 'D'" class="d-block d-md-none">DIC -
													Dudoso recaudo</span>
											</div>
										</div>
									</div>
									<div *ngIf="tabActive == 4 || tabActive4" id="tabActive4">
										<div class="tab_content"
											*ngFor="let comp of getComportSlice(4,5); let i = index">
											<div class="month anio-table">{{ comp.anio }}</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[0] === '-' || comp.comp[0] === '',
												'up-to-date': comp.comp[0] === 'N',
												'punished': comp.comp[0] === 'C',
												'dude': comp.comp[0] === 'D',
												'days-30': comp.comp[0] === '1',
												'days-60': comp.comp[0] === '2',
												'more-60-days': comp.comp[0] === '3' || comp.comp[0] === '4' || comp.comp[0] === '5' || comp.comp[0] === '6'
												}">
												<span
													*ngIf="comp.comp[0] === '1' || comp.comp[0] === '2' || comp.comp[0] === '3' || comp.comp[0] === '4' || comp.comp[0] === '5' || comp.comp[0] === '6'">{{
													comp.comp[0] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[0] === '-'" class="d-block d-md-none">ENE - Sin
													información</span>
												<span *ngIf="comp.comp[0] === 'N'" class="d-block d-md-none">ENE - Al
													día</span>
												<span *ngIf="comp.comp[0] === 'C'" class="d-block d-md-none">ENE -
													Cartera castigada</span>
												<span *ngIf="comp.comp[0] === 'D'" class="d-block d-md-none">ENE -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[1] === '-' || comp.comp[1] === '',
												'up-to-date': comp.comp[1] === 'N',
												'punished': comp.comp[1] === 'C',
												'dude': comp.comp[1] === 'D',
												'days-30': comp.comp[1] === '1',
												'days-60': comp.comp[1] === '2',
												'more-60-days': comp.comp[1] === '3' || comp.comp[1] === '4' || comp.comp[1] === '5' || comp.comp[1] === '6'
												}">
												<span
													*ngIf="comp.comp[1] === '1' || comp.comp[1] === '2' || comp.comp[1] === '3' || comp.comp[1] === '4' || comp.comp[1] === '5' || comp.comp[1] === '6'">{{
													comp.comp[1] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[1] === '-'" class="d-block d-md-none">FEB - Sin
													información</span>
												<span *ngIf="comp.comp[1] === 'N'" class="d-block d-md-none">FEB - Al
													día</span>
												<span *ngIf="comp.comp[1] === 'C'" class="d-block d-md-none">FEB -
													Cartera castigada</span>
												<span *ngIf="comp.comp[1] === 'D'" class="d-block d-md-none">FEB -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[2] === '-' || comp.comp[2] === '',
												'up-to-date': comp.comp[2] === 'N',
												'punished': comp.comp[2] === 'C',
												'dude': comp.comp[2] === 'D',
												'days-30': comp.comp[2] === '1',
												'days-60': comp.comp[2] === '2',
												'more-60-days': comp.comp[2] === '3' || comp.comp[2] === '4' || comp.comp[2] === '5' || comp.comp[2] === '6'
												}">
												<span
													*ngIf="comp.comp[2] === '1' || comp.comp[2] === '2' || comp.comp[2] === '3' || comp.comp[2] === '4' || comp.comp[2] === '5' || comp.comp[2] === '6'">{{
													comp.comp[2] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[2] === '-'" class="d-block d-md-none">MAR - Sin
													información</span>
												<span *ngIf="comp.comp[2] === 'N'" class="d-block d-md-none">MAR - Al
													día</span>
												<span *ngIf="comp.comp[2] === 'C'" class="d-block d-md-none">MAR -
													Cartera castigada</span>
												<span *ngIf="comp.comp[2] === 'D'" class="d-block d-md-none">MAR -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[3] === '-' || comp.comp[3] === '',
												'up-to-date': comp.comp[3] === 'N',
												'punished': comp.comp[3] === 'C',
												'dude': comp.comp[3] === 'D',
												'days-30': comp.comp[3] === '1',
												'days-60': comp.comp[3] === '2',
												'more-60-days': comp.comp[3] === '3' || comp.comp[3] === '4' || comp.comp[3] === '5' || comp.comp[3] === '6'
												}">
												<span
													*ngIf="comp.comp[3] === '1' || comp.comp[3] === '2' || comp.comp[3] === '3' || comp.comp[3] === '4' || comp.comp[3] === '5' || comp.comp[3] === '6'">{{
													comp.comp[3] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[3] === '-'" class="d-block d-md-none">ABR - Sin
													información</span>
												<span *ngIf="comp.comp[3] === 'N'" class="d-block d-md-none">ABR - Al
													día</span>
												<span *ngIf="comp.comp[3] === 'C'" class="d-block d-md-none">ABR -
													Cartera castigada</span>
												<span *ngIf="comp.comp[3] === 'D'" class="d-block d-md-none">ABR -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[4] === '-' || comp.comp[4] === '',
												'up-to-date': comp.comp[4] === 'N',
												'punished': comp.comp[4] === 'C',
												'dude': comp.comp[4] === 'D',
												'days-30': comp.comp[4] === '1',
												'days-60': comp.comp[4] === '2',
												'more-60-days': comp.comp[4] === '3' || comp.comp[4] === '4' || comp.comp[4] === '5' || comp.comp[4] === '6'
												}">
												<span
													*ngIf="comp.comp[4] === '1' || comp.comp[4] === '2' || comp.comp[4] === '3' || comp.comp[4] === '4' || comp.comp[4] === '5' || comp.comp[4] === '6'">{{
													comp.comp[4] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[4] === '-'" class="d-block d-md-none">MAY - Sin
													información</span>
												<span *ngIf="comp.comp[4] === 'N'" class="d-block d-md-none">MAY - Al
													día</span>
												<span *ngIf="comp.comp[4] === 'C'" class="d-block d-md-none">MAY -
													Cartera castigada</span>
												<span *ngIf="comp.comp[4] === 'D'" class="d-block d-md-none">MAY -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[5] === '-' || comp.comp[5] === '',
												'up-to-date': comp.comp[5] === 'N',
												'punished': comp.comp[5] === 'C',
												'dude': comp.comp[5] === 'D',
												'days-30': comp.comp[5] === '1',
												'days-60': comp.comp[5] === '2',
												'more-60-days': comp.comp[5] === '3' || comp.comp[5] === '4' || comp.comp[5] === '5' || comp.comp[5] === '6'
												}">
												<span
													*ngIf="comp.comp[5] === '1' || comp.comp[5] === '2' || comp.comp[5] === '3' || comp.comp[5] === '4' || comp.comp[5] === '5' || comp.comp[5] === '6'">{{
													comp.comp[5] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[5] === '-'" class="d-block d-md-none">JUN - Sin
													información</span>
												<span *ngIf="comp.comp[5] === 'N'" class="d-block d-md-none">JUN - Al
													día</span>
												<span *ngIf="comp.comp[5] === 'C'" class="d-block d-md-none">JUN -
													Cartera castigada</span>
												<span *ngIf="comp.comp[5] === 'D'" class="d-block d-md-none">JUN -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[6] === '-' || comp.comp[6] === '',
												'up-to-date': comp.comp[6] === 'N',
												'punished': comp.comp[6] === 'C',
												'dude': comp.comp[6] === 'D',
												'days-30': comp.comp[6] === '1',
												'days-60': comp.comp[6] === '2',
												'more-60-days': comp.comp[6] === '3' || comp.comp[6] === '4' || comp.comp[6] === '5' || comp.comp[6] === '6'
												}">
												<span
													*ngIf="comp.comp[6] === '1' || comp.comp[6] === '2' || comp.comp[6] === '3' || comp.comp[6] === '4' || comp.comp[6] === '5' || comp.comp[6] === '6'">{{
													comp.comp[6] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[6] === '-'" class="d-block d-md-none">JUL - Sin
													información</span>
												<span *ngIf="comp.comp[6] === 'N'" class="d-block d-md-none">JUL - Al
													día</span>
												<span *ngIf="comp.comp[6] === 'C'" class="d-block d-md-none">JUL -
													Cartera castigada</span>
												<span *ngIf="comp.comp[6] === 'D'" class="d-block d-md-none">JUL -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[7] === '-' || comp.comp[7] === '',
												'up-to-date': comp.comp[7] === 'N',
												'punished': comp.comp[7] === 'C',
												'dude': comp.comp[7] === 'D',
												'days-30': comp.comp[7] === '1',
												'days-60': comp.comp[7] === '2',
												'more-60-days': comp.comp[7] === '3' || comp.comp[7] === '4' || comp.comp[7] === '5' || comp.comp[7] === '6'
												}">
												<span
													*ngIf="comp.comp[7] === '1' || comp.comp[7] === '2' || comp.comp[7] === '3' || comp.comp[7] === '4' || comp.comp[7] === '5' || comp.comp[7] === '6'">{{
													comp.comp[7] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[7] === '-'" class="d-block d-md-none">AGO - Sin
													información</span>
												<span *ngIf="comp.comp[7] === 'N'" class="d-block d-md-none">AGO - Al
													día</span>
												<span *ngIf="comp.comp[7] === 'C'" class="d-block d-md-none">AGO -
													Cartera castigada</span>
												<span *ngIf="comp.comp[7] === 'D'" class="d-block d-md-none">AGO -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[8] === '-' || comp.comp[8] === '',
												'up-to-date': comp.comp[8] === 'N',
												'punished': comp.comp[8] === 'C',
												'dude': comp.comp[8] === 'D',
												'days-30': comp.comp[8] === '1',
												'days-60': comp.comp[8] === '2',
												'more-60-days': comp.comp[8] === '3' || comp.comp[8] === '4' || comp.comp[8] === '5' || comp.comp[8] === '6'
												}">
												<span
													*ngIf="comp.comp[8] === '1' || comp.comp[8] === '2' || comp.comp[8] === '3' || comp.comp[8] === '4' || comp.comp[8] === '5' || comp.comp[8] === '6'">{{
													comp.comp[8] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[8] === '-'" class="d-block d-md-none">SEP - Sin
													información</span>
												<span *ngIf="comp.comp[8] === 'N'" class="d-block d-md-none">SEP - Al
													día</span>
												<span *ngIf="comp.comp[8] === 'C'" class="d-block d-md-none">SEP -
													Cartera castigada</span>
												<span *ngIf="comp.comp[8] === 'D'" class="d-block d-md-none">SEP -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[9] === '-' || comp.comp[9] === '',
												'up-to-date': comp.comp[9] === 'N',
												'punished': comp.comp[9] === 'C',
												'dude': comp.comp[9] === 'D',
												'days-30': comp.comp[9] === '1',
												'days-60': comp.comp[9] === '2',
												'more-60-days': comp.comp[9] === '3' || comp.comp[9] === '4' || comp.comp[9] === '5' || comp.comp[9] === '6'
												}">
												<span
													*ngIf="comp.comp[9] === '1' || comp.comp[9] === '2' || comp.comp[9] === '3' || comp.comp[9] === '4' || comp.comp[9] === '5' || comp.comp[9] === '6'">{{
													comp.comp[9] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[9] === '-'" class="d-block d-md-none">OCT - Sin
													información</span>
												<span *ngIf="comp.comp[9] === 'N'" class="d-block d-md-none">OCT - Al
													día</span>
												<span *ngIf="comp.comp[9] === 'C'" class="d-block d-md-none">OCT -
													Cartera castigada</span>
												<span *ngIf="comp.comp[9] === 'D'" class="d-block d-md-none">OCT -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[10] === '-' || comp.comp[10] === '',
												'up-to-date': comp.comp[10] === 'N',
												'punished': comp.comp[10] === 'C',
												'dude': comp.comp[10] === 'D',
												'days-30': comp.comp[10] === '1',
												'days-60': comp.comp[10] === '2',
												'more-60-days': comp.comp[10] === '3' || comp.comp[10] === '4' || comp.comp[10] === '5' || comp.comp[10] === '6'
												}">
												<span
													*ngIf="comp.comp[10] === '1' || comp.comp[10] === '2' || comp.comp[10] === '3' || comp.comp[10] === '4' || comp.comp[10] === '5' || comp.comp[10] === '6'">{{
													comp.comp[10] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[10] === '-'" class="d-block d-md-none">NOV - Sin
													información</span>
												<span *ngIf="comp.comp[10] === 'N'" class="d-block d-md-none">NOV - Al
													día</span>
												<span *ngIf="comp.comp[10] === 'C'" class="d-block d-md-none">NOV -
													Cartera castigada</span>
												<span *ngIf="comp.comp[10] === 'D'" class="d-block d-md-none">NOV -
													Dudoso recaudo</span>
											</div>
											<div class="month" [ngClass]="{
												'without-info': comp.comp[11] === '-' || comp.comp[11] === '',
												'up-to-date': comp.comp[11] === 'N',
												'punished': comp.comp[11] === 'C',
												'dude': comp.comp[11] === 'D',
												'days-30': comp.comp[11] === '1',
												'days-60': comp.comp[11] === '2',
												'more-60-days': comp.comp[11] === '3' || comp.comp[11] === '4' || comp.comp[11] === '5' || comp.comp[11] === '6'
												}">
												<span
													*ngIf="comp.comp[11] === '1' || comp.comp[11] === '2' || comp.comp[11] === '3' || comp.comp[11] === '4' || comp.comp[11] === '5' || comp.comp[11] === '6'">{{
													comp.comp[11] * 30 }} días en mora</span>
												<span *ngIf="comp.comp[11] === '-'" class="d-block d-md-none">DIC - Sin
													información</span>
												<span *ngIf="comp.comp[11] === 'N'" class="d-block d-md-none">DIC - Al
													día</span>
												<span *ngIf="comp.comp[11] === 'C'" class="d-block d-md-none">DIC -
													Cartera castigada</span>
												<span *ngIf="comp.comp[11] === 'D'" class="d-block d-md-none">DIC -
													Dudoso recaudo</span>
											</div>
										</div>
									</div>
									<div class="conventions">
										<div class="box left-con">
											<div class="box-convention">
												<div class="box-square up-to-date"></div>
												<p>Al día</p>
											</div>
											<div class="box-convention">
												<div class="box-square without-info"></div>
												<p>Sin información</p>
											</div>
										</div>
										<div class="box center-con">
											<p>Días en mora</p>
											<div class="box-convention">
												<div class="box-square days-30">
													<p>30</p>
												</div>
												<div class="box-square days-60">
													<p>60</p>
												</div>
												<div class="box-square more-60-days">
													<p>90</p>
												</div>
											</div>
											<div class="box-convention">
												<div class="box-square more-60-days">
													<p>120</p>
												</div>
												<div class="box-square more-60-days">
													<p>150</p>
												</div>
												<div class="box-square more-60-days">
													<p>180</p>
												</div>
											</div>
										</div>
										<div class="box right-con">
											<div class="box-convention">
												<div class="box-square dude">
													<p>D</p>
												</div>
												<p>Obligación en dudoso recaudo</p>
											</div>
											<div class="box-convention">
												<div class="box-square punished">
													<p>C</p>
												</div>
												<p>Obligación cartera castigada</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="claims">
								<div class="alert-claim">
									<img src="assets/img/freepremium/crear-alertas/nota.svg" alt="Alerta" />
									<p>¿Evidencias alguna inconsistencia en este reporte que amerite una reclamación?.
										<a (click)="goReclamation()" id="formularReclamo" webActivity
											data-idtag="Freemium" data-nametag="formularReclamo">Formula tu reclamo</a>
									</p>
								</div>
							</div>
							<div class="alert-payment" *ngIf="maintenanceAlert">
								<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
								<p>{{sesion.intermittenceClaimHour.messageClaim}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>