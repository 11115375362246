<div class="contentHistoricov2">
    <div class="container-fluid ppading-lr-0-mob">
        <div class="row justify-content-center">
            <!-- Menu lateral freemium -->
            <div class="main col-12 ppading-lr-0-mob">
                <div class="alert-promo" *ngIf="getAuthorizations()">
                    <div class="img-promo">
                        <img src="assets/img/freepremium/publicidad/icono_envio_comunicacion.svg"
                            alt="Alerta de publicidad">
                    </div>
                    <div class="txt-promo" *ngIf="modal.txtDefault">
                        <h4>¡Mantente informado de todo lo que Midatacrédito tiene para ti!</h4>
                        <p>Recibe información comercial y de mercadeo de productos y servicios de Experian Colombia de
                            acuerdo con esta autorización.<a href="{{autorizacion}}" target="_blank">Ver
                                autorización.</a></p>
                    </div>
                    <div class="txt-promo" *ngIf="modal.txtAprroved">
                        <h4>¡Muy bien! Estaremos en contacto.</h4>
                        <p>Pronto recibirás el mejor contenido financiero, para que lleves tus finanzas a otro nivel. <a
                                class="link-aproved" href="{{autorizacion_opcional}}" target="_blank">Ver autorización.</a>
                        </p>
                    </div>
                    <div class="btn-promo acceptPublicity">
                        <a (click)="guardarTerminosPublicidad()" target="_blank"
                            class="btn-midc btn-outline-white h-lg-btn w-100-btn acceptPublicity" id="aceptoPublicidad"
                            webActivity data-idtag="Freemium" data-nametag="aceptoPublicidad">
                            <i class="fa fa-check acceptPublicity" aria-hidden="true"></i>
                            <span class="acceptPublicity">Acepto</span>
                        </a>
                    </div>
                </div>
                <div class="alert-payment" *ngIf="alertPayment && (sesion.estadoUserF == 'ACTIVO' || sesion.estadoUserF == 'ACTIVE_PROCESS')">
                    <img src="assets/img/freepremium/crear-alertas/nota.svg" alt="Alerta" />
                    <p>No es posible realizar un pago ya que cuentas actualmente con un <strong>plan activo</strong>.
                    </p>
                </div>
                <div *ngIf="sesion.viewFree" id='div-gpt-ad-1694398587898-0' class="commercial-banner-principal" [ngClass]="checkBannerFreeBeforeScore()"></div>
                <!-- box puntaje free -->
                <div class="row" *ngIf="(sesion.viewFree && !sesion.campainFree)">
                    <div class="col-12">
                        <div class="boxMejora">
                            <div class="imgMejoraMob">
                                <img src="assets/img/freepremium/historico/Icono_Score_desconocidoN.svg" alt="score desconocido"/>
                                <h1>{{ sesion.user != undefined ? sesion.user.nombre :'' }}, bienvenido a tu historia de Crédito</h1>
                            </div>
                            <div class="contentMejora">
                                <div class="imgMejora">
                                    <img src="assets/img/freepremium/historico/Icono_Score_desconocidoN.svg" alt="score desconocido" />
                                </div>
                                <div class="txtMejora">
                                    <h1>{{ sesion.user != undefined ? sesion.user.nombre :'' }}, conoce tu historia de
                                        crédito</h1>
                                    <p>¿Te gustaría conocer tu PUNTAJE en DATACRÉDITO? Suscríbete a nuestros planes y descúbrelo hoy.</p>
                                    <label>Además de consultar tu puntaje ilimitadamente, podrás tener un diagnóstico de tu perfil crediticio, descargar tu historia de crédito en PDF y más.</label>
                                    <button class="btn-secondary" (click)="seleccionarPlan(sesion.planes.planes.per, '1', sesion.planes.planes.per.mensual[1], sesion.planes.planes.per.mensual[0], 'HCSS')" id="planPerfilCredito"
                                        webActivity data-idtag="Freemium" data-nametag="planPerfilCredito">
                                        Conocer MI PUNTAJE
                                        <i class="fa fa-chevron-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- box puntaje private -->
                <div class="row" *ngIf="sesion.estadoUserF == 'ACTIVO' || sesion.estadoUserF == 'ACTIVE_PROCESS'" [ngClass]="{'text-score' : showTextScore()}">
                    <div class="col-12 col-lg-5">
                        <app-score [diagnostico]="false"></app-score>
                    </div>
                    <div class="col-12 col-lg-7">
                        <h2 *ngIf="sesion.miscore" class="mdc-title mdc-text-secondary mt-0 mt-md-5">{{titleScore}}</h2>
                        <p *ngIf="sesion.miscore" class="mdc-text-secondary">{{descScore}}</p>
                        <a *ngIf="sesion.miscore" class="diagnostico mdc-link-detail text-center"
                            routerLink="/private/diagnostico">
                            <button type="button" class="btn btn-block mdc-btn-primary pr-md-4 m-auto m-lg-0">
                                {{sesion.dataUser.params[13].value?txtButton:'Ver mi diagnóstico'}} <i class="fa fa-chevron-right float-right  mt-arrow"></i>
                            </button>
                        </a>
                    </div>
                </div>
                <!-- box puntaje campainFree -->
                <div class="row" *ngIf="sesion.viewFree && sesion.campainFree">
                    <div class="col-12 col-lg-5">
                        <app-score [diagnostico]="false"></app-score>
                    </div>
                    <div class="col-12 col-lg-7 txtMejora campainFree">
                        <h1>{{ sesion.user != undefined ? sesion.user.nombre :'' }},     este es tu puntaje de crédito</h1>
                        <p class="fecha">Estará disponible para tu consulta hasta el {{sesion.campainfinalDate}}</p>
                        <div class="boxFecha">
                            <i class="fa fa-exclamation-circle infoC"(click)="getTip(true)"></i>
                            <div class="tipCampain" id="contenTooltip">
                                <button (click)="getTip(false)">x</button>
                                <p class="textTipCampain" >El Puntaje de Crédito es una <b>característica paga</b>, que por tiempo limitado va a estar disponible para tu consulta de forma <b>gratuita</b>.</p>
                                <i class="fa fa-sort-down"></i>
                            </div>
                        </div>
                        <label>Si quieres consultar tu puntaje ilimitadamente, tener un diagnóstico de tu perfil crediticio y mucho más, te invitamos a comprar uno de nuestros planes.</label>
                        <button class="btn-secondary" (click)="seleccionarPlan(sesion.planes.planes.per, '1', sesion.planes.planes.per.mensual[1], sesion.planes.planes.per.mensual[0], 'HCSS')" id="planPerfilCredito"
                            webActivity data-idtag="Freemium" data-nametag="planPerfilCredito">
                            Comprar PLAN
                            <i class="fa fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
                <div *ngIf="sesion.viewFree" id='div-gpt-ad-1687365436781-0' class="commercial-banner" [ngClass]="checkBannerFree2()"></div>
                <div class="row" *ngIf="cuentasAbiertas() == undefined">
                    <div class="col-12">
                        <app-micro-loader [texto]="textoMicroLoader" [error]="sesion.errorServiceCreditHistory">
                        </app-micro-loader>
                    </div>
                </div>
                <div class="row" *ngIf="cuentasAbiertas()">
                    <div class="col-12 ppading-lr-0-mob">
                        <div class="boxHistorico" [ngClass]="{'total-border' : (sesion.estadoUserF != 'INACTIVO') }">
                            <div class="tituloHistorico">
                                <h2>Historia de crédito</h2>
                                <p>Tu historia de crédito se compone de los reportes positivos y negativos de tus
                                    productos con entidades que reportan a DataCrédito.</p>
                            </div>
                            <div id="accordionHistorico">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <button class="btnAcordeon" data-toggle="collapse" data-target="#collapseOne"
                                            aria-expanded="false" aria-controls="collapseOne"
                                            (click)="abrirAcordeon('headingOne')" id="cOpen">
                                            <div class="imgBtnAcordeon">
                                                <img
                                                    src="assets/img/freepremium/historico/Ico-hc-CuentasAbiertas.svg" alt="Cuentas Abiertas"/>
                                            </div>
                                            <div class="textBtnAcordeon">
                                                <!-- <span>{{totalCA()}} Cuentas abiertas</span><br /> -->
                                                <span>{{totalCA()}} Cuentas abiertas</span><br />
                                                Tus productos activos
                                            </div>
                                            <i class="fa fa-chevron-right"></i>
                                        </button>
                                    </div>
                                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                                        data-parent="#accordionHistorico" [ngClass]="{'show':this.sesion.allHistory.botonPAD}">
                                        <div class="card-body">
                                            <div class="cuentas">
                                                <div class="cuenta" *ngFor="let cuetaA of cuentasAbiertas();">
                                                    <p>{{cuetaA.nombreCuenta}}</p>
                                                    <div class="detalleCuenta"
                                                        *ngFor="let detalleCA of cuetaA.detalleCuenta;">
                                                        <div class="contenido-cuenta">
                                                            <div class="img-txt" (click)="irDetalle(cuetaA, detalleCA)">
                                                                <div class="imgCuenta">
                                                                    <img [src]="imgCuenta(detalleCA.tipoCuenta)" alt="Tipo Cuenta"/>
                                                                </div>
                                                                <div class="textosCuentas">
                                                                    <p class="nombreCuenta">
                                                                        {{capitalizeFLetter(detalleCA.entidad)}}</p>
                                                                    <!-- <p class="numeroCuenta">{{detalleCA.numeroCuenta}}</p> -->
                                                                    <p class="numeroCuenta"
                                                                        *ngIf="cuetaA.nombreCuenta == 'Mis cuentas de ahorro'">
                                                                        No. **{{detalleCA.numeroCuenta}}</p>
                                                                    <p class="numeroCuenta"
                                                                        *ngIf="cuetaA.nombreCuenta != 'Mis cuentas de ahorro'">
                                                                        No. {{detalleCA.numeroCuenta}}</p>
                                                                    <!-- <p class="fechaCuenta">{{formatDate(detalleCA.fechaActualizacion)}}</p> -->
                                                                    <p class="fechaCuenta">
                                                                        {{detalleCA.fechaActualizacion | date}}</p>
                                                                    <p class="tipoReporteCuenta"
                                                                        [ngClass]="[tipoReporte(detalleCA.tipoReporte)]">
                                                                        {{detalleCA.tipoReporte}}</p>
                                                                </div>
                                                            </div>
                                                            <div (click)="openModalPadYa(detalleCA)" class="pad" *ngIf="isPadYa(detalleCA)">
                                                                <p><b>Ponte al día</b> te ayuda a negociar está obligación.</p>
                                                                <button [id]="'btn-padya-'+detalleCA.numeroCuenta"><span>Ponte al día</span><span class="custom-loader"></span></button>
                                                            </div>
                                                        </div>
                                                        <div class="irDetalle" (click)="irDetalle(cuetaA, detalleCA)">
                                                            <i class="fa fa-arrow-right"></i>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="validarBuscacredito(cuetaA.nombreCuenta)"
                                                        class="detalleCuenta detalleBuscacredito buscacredito-historia"
                                                        (click)="irBuscacredito(cuetaA.nombreCuenta)">
                                                        <div class="contenido-cuenta">
                                                            <div class="img-txt">
                                                                <div class="imgCuenta buscacredito-historia">
                                                                    <img class="buscacredito-historia" alt="buscacrédito"
                                                                        src="assets/img/freepremium/tipo-producto/favicon_buscacredito.svg" />
                                                                </div>
                                                                <div class="textosCuentas buscacredito-historia">
                                                                    <p class="buscacredito-historia">Conoce todos los
                                                                        productos diseñados para ti</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="irDetalle buscacredito-historia">
                                                            <i class="fa fa-arrow-right buscacredito-historia"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="sesion.viewFree" id='div-gpt-ad-1689808214367-0' class="commercial-bannerS2"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <button class="btnAcordeon" data-toggle="collapse" data-target="#collapseTwo"
                                            aria-expanded="false" aria-controls="collapseTwo"
                                            (click)="abrirAcordeon('headingTwo')">
                                            <div class="imgBtnAcordeon">
                                                <img
                                                    src="assets/img/freepremium/historico/Ico-hc-CuentasCerradas.svg" alt="cuentas cerradas"/>
                                            </div>
                                            <div class="textBtnAcordeon">
                                                <span>{{totalCC()}} Cuentas cerradas</span><br />
                                                Productos cerrados o cancelados
                                            </div>
                                            <i class="fa fa-chevron-right"></i>
                                        </button>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                        data-parent="#accordionHistorico">
                                        <div class="card-body">
                                            <div class="cuentas">
                                                <div class="cuenta" *ngFor="let cuetaC of cuentasCerradas();">
                                                    <p>{{cuetaC.nombreCuenta}}</p>
                                                    <div class="detalleCuenta"
                                                        *ngFor="let detalleCC of cuetaC.detalleCuenta;"
                                                        (click)="irDetalle(cuetaC, detalleCC)">
                                                        <div class="contenido-cuenta">
                                                            <div class="img-txt">
                                                                <div class="imgCuenta">
                                                                    <img [src]="imgCuenta(detalleCC.tipoCuenta)" alt="Tipo cuenta"/>
                                                                </div>
                                                                <div class="textosCuentas">
                                                                    <p class="nombreCuenta">
                                                                        {{capitalizeFLetter(detalleCC.entidad)}}</p>
                                                                    <!-- <p class="numeroCuenta">{{detalleCC.numeroCuenta}}</p> -->
                                                                    <p class="numeroCuenta"
                                                                        *ngIf="cuetaC.nombreCuenta == 'Mis cuentas de ahorro'">
                                                                        No. **{{detalleCC.numeroCuenta}}</p>
                                                                    <p class="numeroCuenta"
                                                                        *ngIf="cuetaC.nombreCuenta != 'Mis cuentas de ahorro'">
                                                                        No. {{detalleCC.numeroCuenta}}</p>
                                                                    <!-- <p class="fechaCuenta">{{formatDate(detalleCC.fechaActualizacion)}}</p> -->
                                                                    <p class="fechaCuenta">
                                                                        {{detalleCC.fechaActualizacion | date}}</p>
                                                                    <p class="tipoReporteCuenta"
                                                                        [ngClass]="[tipoReporte(detalleCC.tipoReporte)]">
                                                                        {{detalleCC.tipoReporte}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="irDetalle">
                                                            <i class="fa fa-arrow-right"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="sesion.viewFree" id='div-gpt-ad-1694798549134-0' class="commercial-bannerS2"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <button class="btnAcordeon" data-toggle="collapse" data-target="#collapseThree"
                                            aria-expanded="false" aria-controls="collapseThree"
                                            (click)="abrirAcordeon('headingThree')">
                                            <div class="imgBtnAcordeon">
                                                <img src="assets/img/freepremium/historico/Ico-hc-Reclamaciones.svg" alt="icono reclamaciones"/>
                                            </div>
                                            <div class="textBtnAcordeon">
                                                <span>{{totalRe() + totalCB()}} Reclamaciones</span><br />
                                                Inconformidades en tus reportes
                                            </div>
                                            <i class="fa fa-chevron-right"></i>
                                        </button>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                        data-parent="#accordionHistorico">
                                        <div class="card-body">
                                            <app-mis-reclamaciones *ngIf="totalRe() > 0" [data]="onlyRec"
                                                [showInfo]="false" [noPadding]="true"></app-mis-reclamaciones>

                                            <div *ngIf="totalRe() <= 0 && totalCB() <= 0" class="boxNoContenido">
                                                <div class="imgNoContenido">
                                                    <img src="assets/img/freepremium/Ico-hc-ReclamacionesNo.svg" alt="icono reclamaciones"/>
                                                </div>
                                                <div class="textNoContenido">
                                                    <p>
                                                        <b>No tienes reclamaciones en curso</b>
                                                    </p>
                                                    <p>
                                                        Si tienes una inconformidad en tus reportes, en el detalle
                                                        puedes generar una reclamación.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="cuentas-bloqueada" *ngIf="totalCB() > 0">
                                                <p class="title-b">Cuentas pendientes de solución oportuna de reclamos
                                                    y/o cuentas con reclamo por suplantación de identidad</p>
                                                <div class="cuentas-b">
                                                    <div class="detalleCuentaB" *ngFor="let oCB of onlyCB">
                                                        <div class="contenido-cuenta">
                                                            <div class="img-txt">
                                                                <div class="imgCuenta">
                                                                    <img [src]="imgCuenta('AHO')" alt="AHO"/>
                                                                </div>
                                                                <div class="textosCuentas">
                                                                    <p class="nombreCuenta">{{oCB.entidad}}
                                                                        <span class="d-block d-md-none">
                                                                            {{oCB.numeroCuenta}}</span>
                                                                    </p>
                                                                    <p class="numeroCuenta">
                                                                        {{oCB.numeroCuenta}}</p>
                                                                    <p class="detalle-text-cuenta">
                                                                        {{oCB.texto}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="sesion.viewFree" id='div-gpt-ad-1694798296992-0' class="commercial-bannerS2"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <button class="btnAcordeon" data-toggle="collapse" data-target="#collapseFour"
                                            aria-expanded="false" aria-controls="collapseFour"
                                            (click)="abrirAcordeon('headingFour')">
                                            <div class="imgBtnAcordeon">
                                                <img src="assets/img/freepremium/historico/Ico-hc-Alertas.svg" alt="icono historico alertas" />
                                            </div>
                                            <div class="textBtnAcordeon">
                                                <span>{{sesion.listadoAlertas?.length}} Alertas</span><br />
                                                Prevención del fraude
                                            </div>
                                            <i class="fa fa-chevron-right"></i>
                                        </button>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                        data-parent="#accordionHistorico">
                                        <div class="card-body">
                                            <div class="alerts-check" *ngIf="sesion.listadoAlertas">
                                                <h3 class="">Mis alertas formuladas</h3>
                                                <table aria-describedby="Mis alertas formuladas">
                                                    <thead>
                                                        <tr class="">
                                                            <th class="">
                                                                Alerta formulada
                                                            </th>
                                                            <th class="">
                                                                Vigente hasta
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let alertaListado of sesion.listadoAlertas">
                                                            <td class="txt-left">
                                                                {{ alertaListado.row.descripcion }}
                                                            </td>
                                                            <td class="txt-right">
                                                                {{ alertaListado.row.fechaTerminacion }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="btnNoContenido" *ngIf="sesion.listadoAlertas == undefined">
                                                    <button class="btn-secondary" (click)="goAlerts()">
                                                        Formular alerta
                                                        <i class="fa fa-chevron-right"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="boxNoContenido" *ngIf="sesion.listadoAlertas == undefined">
                                                <div class="imgNoContenido">
                                                    <img src="assets/img/freepremium/Ico-hc-AlertasNo.svg" alt="icono de no alertas" />
                                                </div>
                                                <div class="textNoContenido">
                                                    <p>
                                                        <b>No has generado ninguna alerta</b>
                                                    </p>
                                                    <p>
                                                        Si extraviaste tu documento o crees que puedes ser víctima de
                                                        algún tipo de fraude Midatacrédito te da la posibilidad de
                                                        informar al sistema financiero.
                                                    </p>
                                                </div>
                                                <div class="btnNoContenido">
                                                    <button class="btn-secondary" (click)="goAlerts()">
                                                        Formular alerta
                                                        <i class="fa fa-chevron-right"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="spinGral" *ngIf="spinnerDebt">
                            <img src="assets/img/spinner.gif" class="spinner-gif" alt="spinner" />
                            <div class="textSpin"><span>Por favor espera un momento</span><br>
                                Estamos gestionando tu solicitud</div>
                        </div>
                        <div class="download" *ngIf="BoxDownloadPdf && (sesion.estadoUserF == 'ACTIVO' || sesion.estadoUserF == 'ACTIVE_PROCESS')">
                            <a class="btn-download-hc" (click)="downloadHC()">
                                <!-- <i class="fa fa-download" aria-hidden="true"></i> -->
                                <img src="assets/img/icons/icono_descarga_hc.svg" alt="Descarga"/>
                                Puedes descargar tu Historia de Crédito
                            </a>
                        </div>
                        <a id="descargaPDFHc" [href]="base64" download="historia_credito.pdf"
                            style="display: none;"></a>
                    </div>
                    <div class="box-message">
                        <i class="fa fa-{{iconMessage}}" aria-hidden="true"></i>
                        {{textMessage}}
                    </div>
                </div>
                <div *ngIf="sesion.viewFree" id='div-gpt-ad-1674161440396-0' class="commercial-banner" [ngClass]="checkBannerFree()"></div>
                <div class="row" *ngIf="sesion.viewFree">
                    <div class="col-12">
                        <img src="assets/img/freepremium/pyp.svg" alt="coin" class="coin" />
                        <div class="textCoin">
                            <img src="assets/img/freepremium/pyp.svg" alt="coin" class="coinMob" />
                            <span class="inferior">Te enseñamos a usar los factores que impactan tu puntaje para que
                                tengas un mejor perfil de crédito.</span>
                            <div class="desInferior">Haz parte de los Colombianos que se preocupan por su realidad
                                financiera y toma el control de tu vida crediticia.</div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="sesion.viewFree">
                    <div class="col-12 ppading-lr-10-mob">
                        <app-tarjeta-plan [planeSel]="planPromo" [funnPlan]="'HC'"></app-tarjeta-plan>
                    </div>
                </div>
                <div class="row" *ngIf="sesion.viewFree">
                    <div class="col-12 col-md-6 offset-md-3">
                        <button class="btn-secondary inf" (click)="go_compare()" id="ComparaPlanesInf"
                            webActivity data-idtag="Freemium" data-nametag="ComparaPlanesInf">
                            Compara todos nuestros planes
                            <i class="fa fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="sesion.viewFree">
                    <div class="col-12">
                        <app-publicidad-productos [publicidad]="'datacredito-empresas'" [bordes]="'bordesTodo'"
                            [padding]="'paddingBig'" [sombra]="'sombraBox'" [ubicacion]="'home'"
                            [marginTop]="'marginTop32'" [flexTable]="'flex-table'">
                        </app-publicidad-productos>
                    </div>
                </div>
                <div class="row fondoFalso" *ngIf="sesion.viewFree">
                    <div class="col-12">
                        <div class="importantes">
                            Nota: Tanto la póliza como la asesoría legal son servicios prestados por SBS Seguros
                            Colombia S.A.<br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>