import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

declare var googletag: any;

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  public utmParams: Params = {};
  public haveUtm: boolean = false;
  private gptScriptLoad = false;

  constructor(public route: ActivatedRoute, public router: Router) { }

  /**
   * Este método sirve para activar el script para los banners de Google Ad Manager
   * cuya librería pertenece a GPT.
   * @returns Si la promesa es resuelta es porque el script ha sido cargado exitosamente o ya ha sido cargado previamente.
   * Si la promesa es rechazada es porque hubo un error en el cargue del script.
  */
  loadGPTscript(): Promise<void>{
    return new Promise((resolve, reject) => {    
      if (this.gptScriptLoad){
        resolve();
      }
    
      const script = document.createElement('script');
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      script.async =  true;
      script.onload = () => {
        this.gptScriptLoad = true;
        resolve();
      }
      script.onerror = () => {
        reject(console.error('Error al cargar el script GPT'));
      }
      document.head.appendChild(script);
    });
  }

  /**
   * Este método Inicializa y publica el banner de Google Ad Manager
   * @param slot Id del Ad Unit
   * @param id Id del div
   * @param size Tamaño del banner
   */
  async addPubliById(slot: string, id: string, size: number[][]): Promise<void> {
    try {
      await this.loadGPTscript();
      googletag.cmd.push(() => {

        const mapping = googletag.sizeMapping()
        .addSize([0, 0], [[320, 50],[300, 50], [320, 100],[300, 100], [300, 250], [336, 280]]) // Configuración de view_port
        .addSize([769, 200], [[728, 90], [970, 250]])
        .addSize([1024, 200], [[728, 90], [970, 90], [970, 250]])
        .build();

        var slotAdd = googletag.pubads().getSlots().find((id_slot: any) => id_slot.getSlotElementId() === id);
        if (!slotAdd) {
          googletag.defineSlot(slot, size, id).defineSizeMapping(mapping).addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
          googletag.display(id);
        } else {
          googletag.pubads().refresh([slotAdd]);
        }
      });
    } catch (error){
      console.log('Error al inicializar banner', error);
    }
  }

  /**
   * Funcion que obtiene y guarda en el sessionstorage los parametros que contengan la utm en su llave
   */
  getUtmParams(setUrl:boolean = true) {
    this.utmParams = {};
    let params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
    for (const key in params) {
      if (key.toLowerCase().includes('utm')) {
        this.utmParams[key] = params[key];
        this.haveUtm = true;
      }
    }
    if (Object.keys(this.utmParams).length !== 0 && this.haveUtm) {
      sessionStorage.setItem('utmParams', JSON.stringify(this.utmParams));
    } else {
      let sessionUtm = JSON.parse(sessionStorage.getItem('utmParams') as string);
      if (sessionUtm != null) {
        this.utmParams = sessionUtm;
        if (this.utmParams != null && setUrl) {
          // setTimeout(() => {
            this.setParams(this.utmParams);
          // }, 100)
        }
      }
    }
  }

  /**
   * Funcion para agregar los parametros utm en la url actual
   * @param params Parametros utm para agregar en la url
   */
  setParams(params: Params) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    }
    )
  }

}
